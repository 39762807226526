import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { DynamicBlogComponent } from './pages/dynamic-blog/dynamic-blog.component';
import { DynamicPageComponent } from './pages/dynamic-page/dynamic-page.component';
import { HomeComponent } from './pages/home/home.component';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { LoginComponent } from './pages/users/login/login.component';
import { UserComponent } from './pages/users/user/user.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: ':lang', component: HomeComponent},
  {path: ':lang/resources', component: DynamicBlogComponent},
  {path: ':lang/resources/thanks', component: ThanksComponent},
  {path: ':lang/resources/:page', component: DynamicBlogComponent},
  {path: ':lang/recursos', component: DynamicBlogComponent},
  {path: ':lang/recursos/gracias', component: ThanksComponent},
  {path: ':lang/recursos/:page', component: DynamicBlogComponent},
  {path: ':lang/ressourcen', component: DynamicBlogComponent},
  {path: ':lang/ressourcen/danke', component: ThanksComponent},
  {path: ':lang/ressourcen/:page', component: DynamicBlogComponent},
  {path: ':lang/ressources', component: DynamicBlogComponent},
  {path: ':lang/ressources/merci', component: ThanksComponent},
  {path: ':lang/ressources/:page', component: DynamicBlogComponent},
  {path: ':lang/contact-us', component: ContactComponent},
  {path: ':lang/contactanos', component: ContactComponent},
  {path: ':lang/kontaktiere-uns', component: ContactComponent},
  {path: ':lang/nous-contacter', component: ContactComponent},
  {path: ':lang/thanks', component: ThanksComponent},
  {path: ':lang/gracias', component: ThanksComponent},
  {path: ':lang/danke', component: ThanksComponent},
  {path: ':lang/merci', component: ThanksComponent},
  {path: ':lang/email-confirmation', component: ThanksComponent},
  {path: ':lang/email-de-confirmacion', component: ThanksComponent},
  {path: ':lang/email-bestätigung', component: ThanksComponent},
  {path: ':lang/confirmation-de-email', component: ThanksComponent},
  {path: ':lang/forgot-password', component: LoginComponent},
  {path: ':lang/contraseña-olvidada', component: LoginComponent},
  {path: ':lang/passwort-vergessen', component: LoginComponent},
  {path: ':lang/mot-de-passe-oublie', component: LoginComponent},
  {path: ':lang/user', component: UserComponent},
  {path: ':lang/user/investment-notification-form', component: UserComponent},
  {path: ':lang/user/formulario-notificacion-inversion', component: UserComponent},
  {path: ':lang/user/anlagemitteilungsformular', component: UserComponent},
  {path: ':lang/user/formulaire-de-notification-de-investissement', component: UserComponent},
  {path: ':lang/login', component: LoginComponent},
  {path: ':lang/:page', component: DynamicPageComponent},
  {path: ':lang/:page/thanks', component: ThanksComponent},
  {path: ':lang/:page/gracias', component: ThanksComponent},
  {path: ':lang/:page/danke', component: ThanksComponent},
  {path: ':lang/:page/merci', component: ThanksComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled', scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
