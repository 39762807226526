<!-- Button trigger modal -->
<div *ngIf="!notFound && home">
    <button type="button" hidden id="modalButton" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal">
    </button>
    
    <div class="modal" id="modal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
      <div class="modal-dialog">
          <div class="centered">
            <img width="100%" src="/assets/christmas.png">
          </div>
      </div>
    </div>
    <div *ngIf="home['mainImage']" [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com' + home['mainImage']['url'] + ')', 'height': '100vh',
    'background-size': 'cover'}">
        <!-- <div class="mt-5" [ngStyle]="{'height': '550px',
    'background-size': 'auto', 'padding-top': '60px'}"> -->
        <div class="container pt-15">
            <h1 id="dynamicTitle" [innerHTML]="convert(dynamicTitle['title'])"></h1>
            <h4>{{home['mainSubtitle']}}</h4>
            <p class="w-50">{{home['mainClaim']}}</p>
            <div>
                <button class="mr-2 mb-2 bg-primary border-primary"><a
                        [href]="translate.currentLang + '/' +home['mainLink1']">{{home['mainButton1']}}</a></button>
                <button *ngIf="translate.currentLang === 'en'" class="bg-info border-info button-sub"><a [href]="translate.currentLang + '/blockchain-strategies-fund'">{{home['mainButton2']}}</a></button>
                <button *ngIf="translate.currentLang === 'es'" class="bg-info border-info button-sub"><a [href]="translate.currentLang + '/blockchain-strategies-fund-es'">{{home['mainButton2']}}</a></button>
                <button *ngIf="translate.currentLang === 'de'" class="bg-info border-info button-sub"><a [href]="translate.currentLang + '/blockchain-strategies-fund-de'">{{home['mainButton2']}}</a></button>
                <button *ngIf="translate.currentLang === 'fr'" class="bg-info border-info button-sub"><a [href]="translate.currentLang + '/blockchain-strategies-fund-fr'">{{home['mainButton2']}}</a></button>
            </div>
        </div>
    </div>
    <div class="products pt-5 pb-5">
        <div class="container" id="products">
            <h6>{{home['productsTitle']}}</h6>
            <h2>{{home['productsSubtitle']}}</h2>
            <div class="row mt-5">
                <div class="col-12 col-lg-3 mb-2" *ngFor="let product of products">
                    <div class="card border h-100 border-primary text-center">
                        <img class="card-img-top text-primary align-self-center"
                            [src]="'https://cms.blockassetmanagement.com'+product['featuredImage']['url']"
                            alt="">
                        <div class="card-body d-flex flex-column">
                            <h5 class="card-title">{{product['title']}}</h5>
                            <p class="card-text">{{product['extract']}}</p>
                            <!-- <a class="mt-auto" [href]="product['slug']">More info +</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row justify-content-center">
                <button><a [href]="home['productsLink']">{{home['productsButton']}}</a></button>
            </div> -->
        </div>
    </div>
    <div class="awards pt-5 pb-5" [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com/uploads/6_2e909be53c.jpg)',
    'background-size': 'cover'}">
        <div class="container">
            <h6>{{home['aboutTitle']}}</h6>
            <h2>{{home['aboutSubtitle']}}</h2>
            <h6>{{home['aboutButton']}}</h6>
            <div class="row mt-5" id="custom">
                <div class="col-lg-4 mb-5 mt-5">
                    <div class="row text-start">
                        <h3 class="text-center mb-5">{{ 'STRATEGY' | translate}}</h3>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Blockchain strategies Fund is global fund of funds providing diversified access to the expertise of superior active managers specializing in different blockchain and digital assets investment strategies.' | translate}}</p>
                        </div>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Actively managed to institutional standards in a EU domiciled investment vehicle by an dedicated investment team with backgrounds in traditional and alternative asset management.' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-5 mt-5">
                    <div class="row text-start">
                        <h3 class="text-center mb-5">{{ 'GOAL' | translate}}</h3>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Single point of access to a diversified mix of managers specializing in the fastest growing and highest returning assets class.' | translate}}</p>
                        </div>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Aims to providing investors with significant capital appreciation in a diversified, risk controlled way.' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-5 mt-5">
                    <div class="row text-start">
                        <h3 class="text-center mb-5">{{ 'PROCESS' | translate}}</h3>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Robust quantitative, qualitative and operational processes help us identify top quality managers.' | translate}}</p>
                        </div>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Laser focus on risk management and best practice to protect investors from the complexity and volatility of the asset class.' | translate}}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-3 mb-5 mt-5">
                    <div class="row text-start">
                        <h3 class="text-center mb-5">{{ 'RESULTS' | translate}}</h3>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Annualised Return since inception: 41% - outperforming Bitcoin and all relevant benchmarks.' | translate}}</p>
                        </div>
                        <div class="col-2 m-0 p-0 text-end">
                            <img [src]="'/assets/iconList.png'" class="img-bullet" height="35px" width="35px" alt="" aria-hidden="true">
                        </div>
                        <div class="col-10 mb-3">
                            <p class="card-text">{{ 'Protecting against volatility with lower drawdowns. Bitcoin had 4 months with a return lower than -25% and BSF none.' | translate}}</p>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="row justify-content-center">
                        <button class="mt-5 bg-primary border-primary"><a
                            [href]="home['aboutLink']">{{home['aboutButton']}}</a></button>
                    </div> -->
        </div>
        <!-- <div class="col-12 col-lg-5 mt-5 pt-5">
    
                <p class="mb-5 text-end">{{home['aboutButton']}}</p>
            </div> -->
        <!--<div class="col-12 col-lg-8">
                    <h6 class="text-center mt-1">{{home['awardsTitle']}}</h6>
                    <div class="row my-5">
                        <div class="">
                            <div class="carousel slide" data-ride="carousel">
                                <div class="d-flex">
                                    <a class="col-12 col-lg-4" *ngFor="let award of awards; let i = index" [href]="award['link']">
                                        <img [id]="i" class="img-mobile " [ngClass]="{'scaled': i==1 && isInit}" [src]="'http://blockassetmanagement.hl1034.dinaserver.com'+award['image']['url']"
                                       height="450px" alt=""></a>
                                </div>
                                <a class="carousel-control-prev" (click)="changeAward('prev')" data-slide="prev">
                                    <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" (click)="changeAward('next')" data-slide="next">
                                    <span class="carousel-control-next-icon"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>-->
    </div>
    <div class="blog pt-5 pb-5">
        <div class="container">
            <h6>{{home['blogTitle']}}</h6>
            <h2>{{home['blogSubtitle']}}</h2>
            <div class="row mt-5">
                <div class="col-12 col-lg-6">
                    <div *ngIf="news" class="row">
                        <div *ngFor="let newsItem of news" class="col-12 mb-3 mx-0" [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com/uploads/Blog_6_2ae0061d43.jpg)',
                        'background-size': 'cover'}">
                            <div class="card-body row p-5">
                                <div class="col-lg-6 text-start align-self-end mb-4">
                                    <div class="news p-2 fit-content">
                                        <a *ngIf="translate.currentLang === 'en'" class="text-white"
                                            [href]="'en/resources?category=news'">News</a>
                                        <a *ngIf="translate.currentLang === 'es'" class="text-white"
                                            [href]="'es/recursos?category=news'">News</a>
                                        <a *ngIf="translate.currentLang === 'de'" class="text-white"
                                            [href]="'de/ressourcen?category=news'">News</a>
                                        <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                                            [href]="'fr/ressources?category=news'">News</a>
                                    </div>
                                </div>
                                <div class="col-lg-6 text-lg-end">
                                    <h4 class="card-title">{{newsItem['title']}}</h4>
                                    <p class="card-text">{{newsItem['extract']}}</p>
                                    <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                                    <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                                    <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                                    <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex col-lg-6">
                    <div class="row h-lg-100">
                        <div *ngIf="newsResearch" class="col-12 col-lg-6 h-lg-100 p-5 mb-3" [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com/uploads/Blog_3_47e7546f90.jpg)',
                        'background-size': 'cover'}">
                            <div class="card-body">
                                <div class="research p-2 fit-content mb-4">
                                    <a *ngIf="translate.currentLang === 'en'" class="text-white"
                                        [href]="'en/resources?category=research'">Research</a>
                                    <a *ngIf="translate.currentLang === 'es'" class="text-white"
                                        [href]="'es/recursos?category=research'">Research</a>
                                    <a *ngIf="translate.currentLang === 'de'" class="text-white"
                                        [href]="'de/ressourcen?category=research'">Research</a>
                                    <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                                        [href]="'fr/ressources?category=research'">Research</a>
                                </div>
                                <h4 class="card-title">{{newsResearch[0]['title']}}</h4>
                                <p class="card-text">{{newsResearch[0]['extract']}}</p>
                                <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources/'+newsResearch[0]['slug']">{{ 'Learn More' | translate}}</a>
                                <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos/'+newsResearch[0]['slug']">{{ 'Learn More' | translate}}</a>
                                <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen/'+newsResearch[0]['slug']">{{ 'Learn More' | translate}}</a>
                                <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources/'+newsResearch[0]['slug']">{{ 'Learn More' | translate}}</a>
                            </div>
                        </div>
                        <div *ngIf="newsInsight" class="col-12 col-lg-6 h-lg-100 p-5 mb-3" [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com/uploads/Blog_4_f615f8e13b.jpg)',
                        'background-size': 'cover'}">
                            <div class="card-body">
                                <div class="insights p-2 fit-content mb-4">
                                    <a *ngIf="translate.currentLang === 'en'" class="text-white"
                                        [href]="'en/resources?category=insights'">Insights</a>
                                    <a *ngIf="translate.currentLang === 'es'" class="text-white"
                                        [href]="'es/recursos?category=insights'">Insights</a>
                                    <a *ngIf="translate.currentLang === 'de'" class="text-white"
                                        [href]="'de/ressourcen?category=insights'">Insights</a>
                                    <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                                        [href]="'fr/ressources?category=insights'">Insights</a>
                                    </div>
                                <h4 class="card-title">{{newsInsight[0]['title']}}</h4>
                                <p class="card-text">{{newsInsight[0]['extract']}}</p>
                                <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources/'+newsInsight[0]['slug']">{{ 'Learn More' | translate}}</a>
                                <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos/'+newsInsight[0]['slug']">{{ 'Learn More' | translate}}</a>
                                <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen/'+newsInsight[0]['slug']">{{ 'Learn More' | translate}}</a>
                                <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources/'+newsInsight[0]['slug']">{{ 'Learn More' | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end mt-5">
                    <button class="bg-info border-info"><a
                            [href]="translate.currentLang + '/' + home['blogButtonLink']">{{home['blogButton']}}</a></button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="newsletter" class="newsletter pt-5 pb-5">
        <div class="container p-5">
            <div class="test justify-content-around row p-5 border border-info">
                <div class="col-12 col-lg-4 mt-4">
                    <h6>{{newsletter['title']}}</h6>
                    <h3>{{newsletter['claim']}}</h3>
                </div>
                <div class="col-12 col-lg-4">
                    <form [formGroup]="newsletterForm" id="myForm">
                        <div class="form-group mb-1">
                            <input class="form-control text-primary" type="text" formControlName="name" placeholder="{{ 'Name' | translate}}">
                        </div>
                        <div class="form-group mb-1">
                            <input class="form-control text-primary" type="email" formControlName="email"
                                placeholder="Email">
                        </div>
                        <div class="form-group">
                            <input class="check-control" type="checkbox" formControlName="privacyPolicy">{{ ' I accept the privacy policy and give my consent for future communications and marketing actions.*' | translate}}
                        </div>
                        <!-- <div class="form-group">
                            <input class="check-control" type="checkbox" formControlName="accessDate"> Permission to access
                            the service *
                        </div>
                        <div class="form-group">
                            <input class="check-control" type="checkbox" formControlName="marketingDate"> Consent for
                            marketing activities *
                        </div>
                        <div class="form-group mb-1">
                            <input class="check-control" type="checkbox" formControlName="profilingDate"> Permission for
                            data profiling *
                        </div> -->
                        <div class="text-end">
                            <button class="bg-primary border-primary pt-1 pb-1" (click)="sendNewsletter()"><a
                                    [href]="">{{newsletter['button']}}</a></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="notFound">
    <app-not-found></app-not-found>
</div>

