import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import * as marked from "marked";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss']
})
export class DynamicPageComponent implements OnInit {
  registerForm!: FormGroup;
  alert = {"type": null, "message": null};
  page;
  url;
  intervalId;
  currentTeam = 0;
  isInit = true;
  isLoaded = false;
  API_URL = environment.apiUrl;
  notFound = false;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private titleService: Title,
    public dom: DomSanitizer,
    private metaService: Meta,
    public translate: TranslateService
  ) {
    this.createForm();
    this.url = dom.bypassSecurityTrustResourceUrl('https://clicaqui.es/2DB3e')
  }

  createForm() {
    this.registerForm = this.formBuilder.group({
      work: [ '',
        Validators.required
      ],
      name: [ '',
        Validators.required
      ],
      surname: [ '',
        Validators.required
      ],
      email: [ '',
        [Validators.required, Validators.email]
      ],
      password: [ '',
        Validators.required
      ],
      phone: [ '',
        Validators.required
      ],
      country: [ '',
        Validators.required
      ],
      accept: [ '',
        Validators.required
      ],
      accessDate: [true,
        Validators.required
      ],
      marketingDate: [true,
        Validators.required
      ],
      profilingDate: [ true,
        Validators.required
      ]
    });
  }

  ngOnInit(): void {
    this.coreService.getPage(this.translate.currentLang, this.activatedRoute.snapshot.params.page).subscribe(result => {
      this.page = result[0];
      if (this.page.isPrivate && !localStorage.getItem('bam_user')) {
        this.router.navigateByUrl('');
      }
      this.titleService.setTitle(this.page['meta']['title']);
      this.metaService.addTags(
        [
          {property: 'og:title', content: this.page['meta']['og_title']},
          {name: 'description', content: this.page['meta']['description']},
          {property: 'og:description', content: this.page['meta']['og_description']},
          {property: 'og:image', content: 'https://cms.blockassetmanagement.com'+this.page['meta']['og_image']['url']},
          {name: 'robots', content: (this.page['meta']['index'] ? "index, " : "noindex, ") + (this.page['meta']['follow'] ? "follow" : "nofollow")},
        ]
      );
      this.isLoaded = true;
      this.page.teams =  this.page.teams?.filter(team => team.locale === this.translate.currentLang);
      this.intervalId = setInterval(() => {
        this.isInit = false;
        this.arraymove(this.page.teams, 0, this.page.teams.length);
        if (this.currentTeam == this.page.teams.length) {
          this.currentTeam = 0;
        } else {
          this.currentTeam++;
        }
        setTimeout(() => {
          Array.from(document.querySelectorAll('.scaled')).forEach(function (el) {
            el.classList.remove('scaled ');
          });
          let someElement = document.getElementById("2");
          if (someElement)
            someElement.className += " scaled";
        }, 100);
      }, 8000);
      if (this.router.url.split('#')[1] !== '') {
        setTimeout(() => {
          document.getElementById(this.router.url.split('#')[1]).scrollIntoView();

        }, 1000);
      }
    })

    setTimeout(() => {
      if (!this.page) {
        this.notFound = true;
      }
    }, 1000);
  }

  filter(year) {
    let filterYear = year === 'all' ? '' : `&_where[0][year_lte]=${year}-12-31&_where[0][year_gte]=${year}-01-01`;
    this.coreService.getAwardsByYear(filterYear).subscribe(awards => {
      this.page.awards = awards;
    })
  }

  convert(content) {
    return marked(content);
  }

  changeTeam(position) {
    this.isInit = false;
    position == 'next' ? this.arraymove(this.page.teams, 0, this.page.teams.length) : this.arraymove(this.page.teams, this.page.teams.length-1, 0);
    if (this.currentTeam == this.page.teams.length && position === 'next') {
      this.currentTeam = 0;
    } else if (this.currentTeam == 0 && position === 'prev') {
      this.currentTeam = this.page.teams.length
    } else {
      position === 'next' ? this.currentTeam++ : this.currentTeam--;
    }

    setTimeout(() => {
      Array.from(document.querySelectorAll('.scaled')).forEach(function (el) {
        el.classList.remove('scaled ');
      });
      let someElement = document.getElementById("2");
      if (someElement)
        someElement.className += " scaled";
    }, 100);
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  registerUser() {
    if (this.registerForm.invalid) {
      this.showErrors(this.registerForm);
      return;
    }

    var currentDate = new Date();

    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //Be careful! January is 0 not 1
    var year = currentDate.getFullYear();

    var dateString = ('0' + date).slice(-2) + "/" + ('0' + (month + 1)).slice(-2) + "/" + year;
    
    const register = {
      work: this.registerForm.get('work')!.value,
      username: this.registerForm.get('email')!.value,
      // username: this.registerForm.get('name')!.value + ' ' + this.registerForm.get('surname')!.value,
      name: this.registerForm.get('name')!.value,
      surname: this.registerForm.get('surname')!.value,
      email: this.registerForm.get('email')!.value,
      password: this.registerForm.get('password')!.value,
      // password: Math.random().toString(36).slice(-8),
      phone: this.registerForm.get('phone')!.value,
      country: this.registerForm.get('country')!.value,
      accept: this.registerForm.get('accept')!.value,
      accessDate: dateString,
      marketingDate: dateString,
      profilingDate: dateString
    };

    this.coreService.postRegister(register).subscribe((result) => {
      // this.router.navigate(['thanks'], { relativeTo: this.activatedRoute });
    }, (error) => {
      this.alert.message = error.error.message[0].messages[0].message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 10000);
    });

    this.coreService.postUser(register).subscribe((result) => {
      this.router.navigate(['thanks'], { relativeTo: this.activatedRoute });
    }, (error) => {
      this.alert.message = error.error.message[0].messages[0].message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 10000);
    });
  }

  onlyOne(checkbox) {
    var checkboxes = document.getElementsByName('check');
    checkboxes.forEach((item) => {
        if (item !== checkbox) item['checked'] = false
    })
  }

  showErrors(formGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.registerForm.get(formValue).invalid && this.registerForm.get(formValue).touched;
  }
}
