<header>
  <div class="container">
    <nav *ngIf="menuItems" class="navbar navbar-expand-lg">
      <div class="container-fluid d-block d-lg-flex">
        <a class="navbar-brand" [href]="translate.currentLang">
          <!-- <img src="/assets/logo.png" class="img-fluid" height="70%" width="70%" alt="Logo"> -->
          <img src="/assets/logo.png" class="d-lg-none img-fluid" height="70%" width="70%" alt="Logo">
          <img src="/assets/logo.png" class="d-none d-lg-flex img-fluid" height="50%" width="50%" alt="Logo">
        </a>
        <i class="fa fa-2x fa-bars navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"></i>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item" *ngFor="let item of menuItems">
              <div *ngIf="!isArrayEmpty(item['subPage'])" class="btn-group d-block text-nowrap" dropdown>
                <a id="button-basic" dropdownToggle class="nav-link dropdown-toggle" aria-controls="dropdown-basic">
                  {{item['title']}}</a>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <div *ngFor="let subPage of item['subPage']">
                    <li role="menuitem"><a class="dropdown-item"
                        [href]="translate.currentLang + '/' +subPage['subPage']['slug']">{{subPage['title']}}</a></li>
                  </div>
                  <!-- <div *ngFor="let userPage of userPages">
                    <li *ngIf="user" role="menuitem"><a class="dropdown-item"
                        [href]="translate.currentLang + '/' +userPage['slug']">{{userPage['title']}}</a></li>
                  </div> -->
                </ul>
              </div>
              <a *ngIf="isArrayEmpty(item['subPage'])" class="nav-link text-nowrap"
                [href]="item['page'] ? translate.currentLang + '/' +item['page']['slug'] : translate.currentLang">{{item['title']}}</a>
            </li>
            <li class="nav-item d-flex align-items-center"><a *ngFor="let socialMedia of socialMedia"
                [href]="socialMedia['type'] == 'LinkedIn' ? 'https://www.linkedin.com/company/'+socialMedia['contact'] : 'https://twitter.com/'+socialMedia['contact']"><img
                  [src]="socialMedia['type'] == 'LinkedIn' ? '/assets/linkedIn.png' : '/assets/twitter.png'"
                  height="32px" alt=""></a>
            </li>
            <!-- <li class="nav-item d-flex align-items-center">
              <a class="nav-link" (click)="changeLanguage('fr')">FR</a>
            </li>
            <li class="nav-item d-flex align-items-center">
              <a class="nav-link" (click)="changeLanguage('de')">DE</a>
            </li>
            <li class="nav-item d-flex align-items-center">
              <a class="nav-link" (click)="changeLanguage('es')">ES</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="changeLanguage('en')">EN</a>
            </li> -->
            <li class="nav-item">
              <div class="btn-group d-block text-nowrap" dropdown>
                <a id="button-flags" dropdownToggle class="nav-link dropdown-toggle" aria-controls="dropdown-flags">
                  <i class="fa fa-sharp fa-globe"></i></a>
                <ul id="dropdown-flags" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-flags">
                  <div><a class="dropdown-item" (click)="changeLanguage('en')">EN</a></div>
                  <!--<div><a class="dropdown-item" (click)="changeLanguage('es')">ES</a></div>-->
                  <div><a class="dropdown-item" (click)="changeLanguage('fr')">FR</a></div>
                  <!--<div><a class="dropdown-item" (click)="changeLanguage('de')">DE</a></div>-->
                </ul>
              </div>
            </li>
            <li *ngIf="!user" class="nav-item">
              <a class="nav-link" [href]="translate.currentLang + '/login'">LOGIN</a>
            </li>
            <!-- <li *ngIf="!user" class="nav-item">
              <a class="nav-link" [href]="translate.currentLang + '/register'">REGISTER</a>
            </li> -->
            <li *ngIf="user" class="nav-item">
              <div class="btn-group d-block text-nowrap" dropdown>
                <a id="button-basic" dropdownToggle class="nav-link dropdown-toggle" aria-controls="dropdown-basic">
                  <i class="fa fa-user"></i></a>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                  <div><a class="dropdown-item" [href]="translate.currentLang + '/user'">{{ 'MY PROFILE' | translate}}</a></div>
                  <div><a class="dropdown-item" [href]="translate.currentLang + '/user/investment-notification-form'">{{ 'NOTIFICATION FORM' | translate}}</a></div>
                  <div *ngFor="let userPage of userPages">
                    <li *ngIf="user" role="menuitem"><a class="dropdown-item"
                        [href]="translate.currentLang + '/' +userPage['slug']">{{userPage['extract']}}</a></li>
                  </div>
                  <div><a class="dropdown-item" (click)="logout()">{{ 'LOGOUT' | translate}}</a></div>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
