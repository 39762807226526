<div class="container ">
    <div class="row">
        <div class="col-6 text-center p-5 mt-5">
            <h2 class="text-primary mt-5">404</h2>
            <h3>{{'PAGE NOT FOUND' | translate}}</h3>
            <p>{{'It seems that the page you are looking for does not exist' | translate}}</p>
            <p class="mt-3">{{ 'Please try again by using' | translate}} <br>
                {{'our navigation menu or' | translate}}</p>
            <button class="bg-info border-info button-sub"><a class="text-white" [href]="translate.currentLang">{{ 'START OVER' | translate}}</a></button>
        </div>
        <div class="col-6">
            <img class="w-100" src="/assets/404.png" height="700px" alt="404">
        </div>
    </div>
</div>