<footer>
  <div *ngIf="footer" class="container">
    <div class="row py-5 px-xl-5 justify-content-between align-items-center text-center">
    <!-- <div class="row p-5 justify-content-between"> -->
      <div class="col-lg-4 col-sm-12 pt-i">
        <a href="">
          <img src="/assets/logo.png" class="img-fluid" height="144" width="384" alt="Logo">
          <!-- <img src="/assets/logo.png" class="logo" height="100%" width="100%" alt="Logo"> -->
        </a>
        <!-- <p class="mt-3">{{footer['claim']}}</p>
                <button><a [href]="footer['buttonLink']">{{footer['button']}}</a></button> -->
      </div>
      <!-- <div class="col-lg-2 col-sm-12 offset-lg-1 pt-i"> -->
      <div class="col-lg-4 col-sm-12 pt-i">
        <h5 class="mb-4">{{footer['productsTitle']}}</h5>
        <!-- <ul *ngIf="menuItems[0]">
                    <li *ngFor="let subPage of menuItems[0]['subPage']"><a class="text-nowrap"
                            [href]="translate.currentLang + '/' +subPage['subPage']['slug']">{{subPage['title']}}</a></li>
                </ul> -->
        <ul>
          <!-- <li><a class="text-nowrap"
            [href]="translate.currentLang + '/' + 'blockchain-strategies-fund-es'">{{'BLOCKHAIN STRATEGIES FUND'}}</a></li> -->
          <li *ngIf="translate.currentLang === 'en'"><a class="text-nowrap"
              [href]="translate.currentLang + '/' + 'blockchain-strategies-fund'">{{'BLOCKCHAIN STRATEGIES FUND'}}</a></li>
          <li *ngIf="translate.currentLang === 'es'"><a class="text-nowrap"
            [href]="translate.currentLang + '/' + 'blockchain-strategies-fund-es'">{{'BLOCKCHAIN STRATEGIES FUND'}}</a></li>
          <li *ngIf="translate.currentLang === 'de'"><a class="text-nowrap"
            [href]="translate.currentLang + '/' + 'blockchain-strategies-fund-de'">{{'BLOCKCHAIN STRATEGIES FUND'}}</a></li>
          <li *ngIf="translate.currentLang === 'fr'"><a class="text-nowrap"
            [href]="translate.currentLang + '/' + 'blockchain-strategies-fund-fr'">{{'BLOCKCHAIN STRATEGIES FUND'}}</a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-sm-12 pt-i">
        <div class="row justify-content-center">
          <!-- <h5 class="col-12 mb-4">{{footer['contactTitle']}}</h5>
          <p class="col-12">{{footer['contactClaim']}}</p> -->
          <ul class="col-6 d-flex">
            <!-- <li *ngFor="let socialMedia of socialMedia"><a [href]="socialMedia['contact']"><i class="m-2" [ngClass]="socialMedia['icon']"></i></a></li> -->
            <li *ngFor="let socialMedia of socialMedia"><a
                [href]="socialMedia['type'] == 'LinkedIn' ? 'https://www.linkedin.com/company/'+socialMedia['contact'] : 'https://twitter.com/'+socialMedia['contact']"><img
                  [src]="socialMedia['type'] == 'LinkedIn' ? '/assets/linkedIn.png' : '/assets/twitter.png'"
                  height="50px" width="50px" alt=""></a></li>
          </ul>
          <button class="col-6 bg-primary border-primary fit-content pt-2 pb-1"><a
              [href]="translate.currentLang + '/' +footer['contactButtonLink']">{{footer['contactButton']}}</a></button>
        </div>
      </div>
    </div>
    <div class="row p-5 border-top border-white justify-content-between">
      <div class="col-12 col-lg-2">
        <p *ngIf="translate.currentLang === 'en'"><a [href]="translate.currentLang + '/' + 'disclaimer'">{{ 'Disclaimer'
            | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'es'"><a
            [href]="translate.currentLang + '/' + 'descargo-de-responsabilidad'">{{ 'Disclaimer' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'de'"><a
          [href]="translate.currentLang + '/' + 'haftungsausschluss'">{{ 'Disclaimer' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'fr'"><a
          [href]="translate.currentLang + '/' + 'responsabilite'">{{ 'Disclaimer' | translate}}</a></p>
      </div>
      <div class="col-12 col-lg-3">
        <p *ngIf="translate.currentLang === 'en'"><a [href]="translate.currentLang + '/' + 'privacy-policy'">{{ 'Privacy
            Policy' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'es'"><a [href]="translate.currentLang + '/' + 'politica-de-privacidad'">{{
            'Privacy Policy' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'de'"><a [href]="translate.currentLang + '/' + 'datenschutz-bestimmungen'">{{
          'Privacy Policy' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'fr'"><a [href]="translate.currentLang + '/' + 'politique-de-confidentialite'">{{
          'Privacy Policy' | translate}}</a></p>
      </div>
      <div class="col-12 col-lg-3">
        <p *ngIf="translate.currentLang === 'en'"><a [href]="translate.currentLang + '/' + 'legal-notices'">{{ 'Legal
            Notice' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'es'"><a [href]="translate.currentLang + '/' + 'nota-legal'">{{ 'Legal
            Notice' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'de'"><a [href]="translate.currentLang + '/' + 'rechtliche-hinweise'">{{ 'Legal
          Notice' | translate}}</a></p>
        <p *ngIf="translate.currentLang === 'fr'"><a [href]="translate.currentLang + '/' + 'notice-legale'">{{ 'Legal
          Notice' | translate}}</a></p>
      </div>
      <div class="col-12 col-lg-4 text-nowrap">
        <p><a>© 2022 Block Asset Management S.à r.l.</a></p>
      </div>
    </div>
  </div>

</footer>