import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
  page;
  confirmation = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.url[1].path === 'email-confirmation' || this.activatedRoute.snapshot.url[1].path === 'email-de-confirmacion' || this.activatedRoute.snapshot.url[1].path === 'email-bestätigung' || this.activatedRoute.snapshot.url[1].path === 'confirmation-de-email') {
      this.confirmation = true;
    }
    this.page = this.activatedRoute.snapshot.params.page;
    console.log(this.page);
  }

}
