import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footer;
  socialMedia = [];
  menuItems = [];
  constructor(
    private coreService: CoreService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.coreService.getFooter(this.translate.currentLang).subscribe( footer => {
      this.footer = footer;
    });

    this.coreService.getSocialMedia().subscribe( result => {
      this.socialMedia = result.socialMedia;
    });

    this.coreService.getMenu(this.translate.currentLang).subscribe( items => {
      this.menuItems = items.page;
    });
  }

}
