import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  alert = { "type": null, "message": null };
  isForgotPassword = false;
  isResetPassword = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private coreService: CoreService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.code) {
      this.isResetPassword = true;
    } else if (this.activatedRoute.snapshot.url[1] && (this.activatedRoute.snapshot.url[1].path == 'forgot-password' || this.activatedRoute.snapshot.url[1].path == 'contraseña-olvidada' || this.activatedRoute.snapshot.url[1].path === 'passwort-vergessen' || this.activatedRoute.snapshot.url[1].path === 'mot-de-passe-oublie')) {
      this.isForgotPassword = true;
    }
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['',
        [Validators.required, Validators.email]
      ],
      password: ['',
        Validators.required
      ]
    });
  }

  login() {
    if (this.loginForm.invalid) {
      this.showErrors(this.loginForm);
      return;
    }


    const login = {
      identifier: this.loginForm.get('email')!.value,
      password: this.loginForm.get('password')!.value
    };

    // this.coreService.postRegister(register).subscribe((result) => {
    //   // this.router.navigate(['thanks'], { relativeTo: this.activatedRoute });
    // }, (error) => {
    //   this.alert.message = error.message;
    //   this.alert.type = 2;
    //   setTimeout(() => {this.alert = {message: null, type: null};
    //   }, 10000);
    // });

    this.coreService.postLogin(login).subscribe((result) => {
      localStorage.setItem('bam_user', JSON.stringify(result['user']));
      localStorage.setItem('bam_jwt', result['jwt'].replace('""', ''));
      window.location.href = this.translate.currentLang + '/factsheet';
      // this.router.navigate([this.translate.currentLang + '/user']);
    }, (error) => {
      this.alert.message = error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 10000);
    });
  }

  forgotPassword() {
    const forgotPassword = {
      email: this.loginForm.get('email')!.value,
      password: this.loginForm.get('password')!.value
    };

    this.coreService.forgotPassword(forgotPassword).subscribe(() => {
      this.alert.message = 'Request sent, shortly you will receive an email to change your password.';
      this.alert.type = 1;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 10000);
    }, (error) => {
      this.alert.message = error.error.message[0].messages[0].message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 10000);
    });
  }

  changePassword() {
    const changePassword = {
      code: this.activatedRoute.snapshot.queryParams.code,
      password: this.loginForm.get('password')!.value,
      passwordConfirmation: this.loginForm.get('password')!.value
    };

    this.coreService.changePassword(changePassword).subscribe(() => {
      window.location.href = this.translate.currentLang + '/login';
    }, (error) => {
      this.alert.message = error.error.message[0].messages[0].message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 10000);
    });
  }

  showErrors(formGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.loginForm.get(formValue).invalid && this.loginForm.get(formValue).touched;
  }
}
