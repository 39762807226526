import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  userForm!: FormGroup;
  notificationForm!: FormGroup;
  currentDate;
  alert = { "type": null, "message": null };
  profile = true;
  currentProductType;
  constructor(
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService,
    private formBuilder: FormBuilder,
    private router: Router,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (!localStorage.getItem('bam_user')) {
      this.router.navigateByUrl('');
    }
    if (this.activatedRoute.snapshot.url[2] && (this.activatedRoute.snapshot.url[2].path == 'investment-notification-form' || this.activatedRoute.snapshot.url[2].path == 'formulario-notificacion-inversion' || this.activatedRoute.snapshot.url[2].path === 'anlagemitteilungsformular' || this.activatedRoute.snapshot.url[2].path === 'formulaire-de-notification-de-investissement')) {
      this.createNotificationForm();
      this.profile = false;
    }
    this.coreService.getUser(JSON.parse(localStorage.getItem('bam_user'))['id']).subscribe((user) => {
      this.userForm.get('work').setValue(user['work']),
        this.userForm.get('username').setValue(user['username']),
        this.userForm.get('name').setValue(user['name']),
        this.userForm.get('surname').setValue(user['surname']),
        this.userForm.get('email').setValue(user['email']),
        this.userForm.get('password').setValue(user['password']),
        this.userForm.get('phone').setValue(user['phone']),
        this.userForm.get('country').setValue(user['country'])

      if (!this.profile) {
        this.notificationForm.get('name').setValue(user['name'] + ' ' + user['surname']),
          this.notificationForm.get('email').setValue(user['email'])
      }
    })
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      work: ['',
        Validators.required
      ],
      name: ['',
        Validators.required
      ],
      username: ['',
        Validators.required
      ],
      password: [''
      ],
      surname: ['',
        Validators.required
      ],
      email: ['',
        [Validators.required, Validators.email]
      ],
      phone: ['',
        Validators.required
      ],
      country: ['',
        Validators.required
      ]
    });
  }

  createNotificationForm() {
    var currentDate = new Date();

    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //Be careful! January is 0 not 1
    var year = currentDate.getFullYear();

    this.currentDate = ('0' + date).slice(-2) + "/" + ('0' + (month + 1)).slice(-2) + "/" + year;
    this.notificationForm = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      email: ['',
        [Validators.required, Validators.email]
      ],
      brokerage: ['',
        Validators.required
      ],
      product: [''
      ],
      isin: ['CH0451798356'
      ],
      class: [''
      ],
      provider: ['',
        Validators.required
      ],
      client: ['',
        Validators.required
      ],
      currency: [''
      ],
      hasInititalCommission: [''
      ],
      amount: [''
      ],
      commission: [''
      ],
      comments: [''
      ],
      currentDate: [this.currentDate,
        Validators.required],
      certificatesAmount: [''
      ],
      accessDate: [true,
        Validators.required
      ],
      marketingDate: [true,
        Validators.required
      ],
      profilingDate: [true,
        Validators.required
      ]
    });
  }

  save() {
    if (this.userForm.invalid) {
      this.showErrors(this.userForm);
      return;
    }

    const user = {
      work: this.userForm.get('work')!.value,
      username: this.userForm.get('username')!.value,
      name: this.userForm.get('name')!.value,
      surname: this.userForm.get('surname')!.value,
      email: this.userForm.get('email')!.value,
      password: this.userForm.get('password')!.value,
      phone: this.userForm.get('phone')!.value,
      country: this.userForm.get('country')!.value
    };

    this.coreService.putUser(JSON.parse(localStorage.getItem('bam_user'))['id'], user).subscribe((result) => {
    }, (error) => {
      this.alert.message = error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 10000);
    });
  }

  saveNotification() {
    this.alert = { message: null, type: null };
    if (this.notificationForm.invalid) {
      this.showErrors(this.notificationForm);
      return;
    }

    // const user = {
    //   work: this.notificationForm.get('work')!.value,
    //   username: this.notificationForm.get('username')!.value,
    //   name: this.notificationForm.get('name')!.value,
    //   surname: this.notificationForm.get('surname')!.value,
    //   email: this.notificationForm.get('email')!.value,
    //   password: this.notificationForm.get('password')!.value,
    //   phone: this.notificationForm.get('phone')!.value,
    //   country: this.notificationForm.get('country')!.value
    // };

    this.notificationForm.get('accessDate').setValue(this.currentDate);
    this.notificationForm.get('marketingDate').setValue(this.currentDate);
    this.notificationForm.get('profilingDate').setValue(this.currentDate);


    this.coreService.postNotificationForm(this.notificationForm.value).subscribe((result) => {
      this.alert.message = 'THANK YOU FOR YOUR SUBMISSION, WE WILL CONTACT YOU AS SOON AS POSSIBLE';
      this.alert.type = 1;
      this.createNotificationForm();
      // setTimeout(() => {
      //   this.alert = { message: null, type: null };
      // }, 10000);
    }, (error) => {
      this.alert.message = error.message;
      this.alert.type = 2;
      this.createNotificationForm();
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 10000);
    });
  }

  amountDots(amount, type) {
    if (!(/^[0-9.]+$/.test(amount))) {
      this.notificationForm.get(type)!.setValue(null);
      return null;
    } else {
      this.notificationForm.get(type)!.setValue((amount.replaceAll('.', '')).replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
      return (amount.replaceAll('.', '')).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  }

  checkMin(amount) {
    if (parseInt(amount) < 2) {
      this.notificationForm.get('certificatesAmount')!.setValue(null);
      return null;
    }
  }
  
  productType(type) {
    this.currentProductType = type;
  }
  // transform() {
  //   if (!(/^[0-9.]+$/.test(this.notificationForm.get('amount')!.value))) {
  //     this.notificationForm.get('amount')!.setValue(null);
  //   } else {
  //     if (this.notificationForm.get('amount')!.value.length > 9) {
  //       let formatedValue = this.notificationForm.get('amount')!.value.replaceAll('.', '');
  //       setTimeout(() => {
  //         this.notificationForm.get('amount')!.setValue((formatedValue).replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
  //       }, 1000);
  //     } else {
  //       this.notificationForm.get('amount')!.setValue((this.notificationForm.get('amount')!.value.replace(".","")).replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
  //     }
  //   }
  // }

  transformComm() {
    if (this.notificationForm.get('commission')!.value > 4) {
      this.notificationForm.get('commission')!.setValue(4);
    } else if (this.notificationForm.get('commission')!.value < 0) {
      this.notificationForm.get('commission')!.setValue(0);
    }
  }

  showErrors(formGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.userForm.get(formValue).invalid && this.userForm.get(formValue).touched;
  }

  showNotificationError(formValue) {
    return this.notificationForm.get(formValue).invalid && this.notificationForm.get(formValue).touched;
  }

}
