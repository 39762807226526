<div>
    <div class="container pb-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 mt-5 px-5">
                <div class="alert alert-primary text-center" role="alert" *ngIf="alert.type == 1">
                    {{alert.message}}
                </div>
                <small class="text-danger text-center" *ngIf="alert.type == 2">
                    {{alert.message}}
                </small>
                <form *ngIf="!isForgotPassword && !isResetPassword" [formGroup]="loginForm" class="p-5 border border-info">
                    <h2 class="text-center mb-4">{{ 'LOGIN' | translate}}</h2>
                    <div class="form-group">
                        <label for="">{{ 'Your Email' | translate}}</label>
                        <input type="text" placeholder="Email" formControlName="email"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('email')">
                        {{ 'This field is required' | translate}}
                    </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Password' | translate}}</label>
                        <input type="password" placeholder="{{ 'Password' | translate}}" name="password" formControlName="password"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('password')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="text-center">
                        <button class="bg-primary border-primary mt-5 p-0 px-3 mx-5" (click)="login()">{{ 'Login' |
                            translate}}</button>
                    </div>

                    <div class="text-center text-primary mt-1">
                        <a *ngIf="translate.currentLang === 'en'" [href]="translate.currentLang + '/forgot-password'">Forgot password?</a>
                        <a *ngIf="translate.currentLang === 'es'" [href]="translate.currentLang + '/contraseña-olvidada'">¿Has olvidado tu contraseña?</a>
                        <a *ngIf="translate.currentLang === 'de'" [href]="translate.currentLang + '/passwort-vergessen'">Passwort vergessen?</a>
                        <a *ngIf="translate.currentLang === 'fr'" [href]="translate.currentLang + '/mot-de-passe-oublie'">Mot de passe oublié?</a>
                    </div>
                    <div class="text-center text-primary mt-2">
                        <a *ngIf="translate.currentLang === 'en'" class="text-primary" [href]="translate.currentLang + '/register'">REGISTER</a>
                        <a *ngIf="translate.currentLang === 'es'" class="text-primary" [href]="translate.currentLang + '/registro'">REGISTRO</a>
                        <a *ngIf="translate.currentLang === 'de'" class="text-primary" [href]="translate.currentLang + '/registrieren'">REGISTRIEREN</a>
                        <a *ngIf="translate.currentLang === 'fr'" class="text-primary" [href]="translate.currentLang + '/es-enregistrer'">S'ENREGISTRER</a>
                    </div>
                </form>
                <form *ngIf="isForgotPassword" [formGroup]="loginForm" class="p-5 border border-info">
                    <h2 class="text-center mb-4">{{ 'Forgot Password' | translate}}</h2>
                    <div class="form-group">
                        <label for="">{{ 'Your Email' | translate}}</label>
                        <input type="text" placeholder="Email" formControlName="email"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('email')">
                        {{ 'This field is required' | translate}}
                    </small>
                    </div>
                    <div class="text-center">
                        <button class="bg-primary border-primary mt-5 p-0 px-3 mx-5" (click)="forgotPassword()">{{ 'Send Email' |
                            translate}}</button>
                    </div>
                </form>
                <form *ngIf="isResetPassword" [formGroup]="loginForm" class="p-5 border border-info">
                    <h2 class="text-center mb-4">{{ 'Reset Password' | translate}}</h2>
                    <div class="form-group">
                        <label for="">{{ 'Introduce New Password' | translate}}</label>
                        <input type="password" placeholder="{{ 'New Password' | translate}}" name="password" formControlName="password"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('password')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="text-center">
                        <button class="bg-primary border-primary mt-5 p-0 px-3 mx-5" (click)="changePassword()">{{ 'Change Password' |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>