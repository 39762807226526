<div *ngIf="isLoaded && ['register', 'registro', 'registrieren', 'es-enregistrer'].includes(page['slug'])" [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com' + page['image']['url'] + ')',
'background-size': 'cover'}">
    <div class="container pb-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 mt-5 px-5">
                <small class="text-success" *ngIf="alert.type == 1">
                    {{alert.message}}
                </small>
                <small class="text-danger" *ngIf="alert.type == 2">
                    {{alert.message}}
                </small>
                <form [formGroup]="registerForm" class="p-5 border border-info">
                    <h2 class="text-center mb-4">{{ 'REGISTER' | translate}}</h2>
                    <!-- <label class="mb-1" for="">{{ 'Please select which one of the following applies to you:' | translate}}</label>
                    <select placeholder="Your Country" formControlName="work" class="form-control text-primary">
                        <option value="Financial Advisor">{{ 'I am a licensed/regulated Financial Advisor or Broker Dealer' | translate}}
                        </option>
                        <option value="Wealth Manager">{{ 'I work for an authorized/regulated Wealth Manager/Family Office' | translate}}
                        </option>
                        <option value="regulated Insurance">{{ 'I work for an authorized/regulated Insurance/Life Co' | translate}}
                        </option>
                        <option value="regulated Bank">{{ 'I work for an authorized/regulated Bank/Private Bank' | translate}}</option>
                        <option value="Fund Manager">{{ 'I am a Fund Manager' | translate}}</option>
                        <option value="qualified investor">{{ 'I am a qualified investor (HNWI or institutional investor)' | translate}}
                        </option>
                    </select> -->
                    <label class="mb-1" for="">{{ 'Investor Type:' | translate}}</label>
                    <select placeholder="Your Country" formControlName="work" class="form-control text-primary">
                        <option value="INDIVIDUAL">{{ 'INDIVIDUAL' | translate}}
                        </option>
                        <option value="FINANCIAL ADVISOR">{{ 'FINANCIAL ADVISOR' | translate}}
                        </option>
                        <option value="INSTITUTIONAL">{{ 'INSTITUTIONAL' | translate}}
                        </option>
                        <option value="FAMILY OFFICE">{{ 'FAMILY OFFICE' | translate}}</option>
                    </select>
                    <small class="text-danger" *ngIf="showError('work')">
                        {{ 'This field is required' | translate}}
                    </small>
                    <!-- <div class="form-group">
                    <input class="check-control" type="checkbox" name="check" (click)="onlyOne($event.target)"> I am a
                    licensed/regulated Financial Advisor or Broker Dealer
                </div>
                <div class="form-group">
                    <input class="check-control" type="checkbox" name="check" (click)="onlyOne($event.target)"> I work
                    for an
                    authorized/regulated Wealth Manager/Family Office
                </div>
                <div class="form-group">
                    <input class="check-control" type="checkbox" name="check" (click)="onlyOne($event.target)"> I work
                    for an
                    authorized/regulated Insurance/Life Co
                </div>
                <div class="form-group">
                    <input class="check-control" type="checkbox" name="check" (click)="onlyOne($event.target)"> I work
                    for an
                    authorized/regulated Bank/Private Bank
                </div>
                <div class="form-group">
                    <input class="check-control" type="checkbox" name="check" (click)="onlyOne($event.target)"> I am a
                    Fund Manager
                </div>
                <div class="form-group mb-2">
                    <input class="check-control" type="checkbox" name="check" (click)="onlyOne($event.target)"> I am a
                    qualified
                    investor (HNWI or institutional investor)
                </div> -->
                    <div class="form-group">
                        <label for="">{{ 'First Name' | translate}}</label>
                        <input type="text" placeholder="{{ 'Name' | translate}}" name="name" formControlName="name"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('name')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Last Name' | translate}}</label>
                        <input type="text" placeholder="{{ 'Last Name' | translate}}" formControlName="surname"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('surname')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Your Email' | translate}}</label>
                        <input type="text" placeholder="Email" formControlName="email"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('email')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Password' | translate}}</label>
                        <input type="text" placeholder="{{ 'Password' | translate}}" formControlName="password"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('password')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Phone Number' | translate}}</label>
                        <input type="text" placeholder="{{ 'Phone Number' | translate}}" formControlName="phone"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('phone')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Country:' | translate}}</label>
                        <select placeholder="Your Country" formControlName="country" class="form-control text-primary">
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The
                            </option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-bissau">Guinea-bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic
                                of
                            </option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav
                                Republic
                                of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South
                                Sandwich
                                Islands</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-leste">Timor-leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands
                            </option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                        <small class="text-danger" *ngIf="showError('country')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <input class="check-control" type="checkbox" formControlName="accept">{{ ' I accept the Reverse Solicitation Policy, Privacy Policy and Legal Disclaimer of Block Asset Management S.à.r.l. And give my consent for future communications and marketing actions*' | translate}} 
                        <small class="text-danger" *ngIf="showError('accept')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <!-- <div class="form-group">
                        <input class="check-control" type="checkbox" formControlName="accessDate"> Permission
                        to access the service *
                        <small class="text-danger" *ngIf="showError('accessDate')">
                            {{'This field is required'}}
                        </small>
                    </div>
                    <div class="form-group">
                        <input class="check-control" type="checkbox" formControlName="marketingDate"> Consent
                        for marketing activities *
                        <small class="text-danger" *ngIf="showError('marketingDate')">
                            {{'This field is required'}}
                        </small>
                    </div>
                    <div class="form-group mb-1">
                        <input class="check-control" type="checkbox" formControlName="profilingDate">
                        Permission for data profiling *
                        <small class="text-danger" *ngIf="showError('profilingDate')">
                            {{'This field is required'}}
                        </small>
                    </div> -->
                    <div class="text-center">
                        <button class="bg-primary border-primary mt-5 p-0 px-3 mx-5"
                            (click)="registerUser()">{{ 'Join' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoaded && ['blockchain-strategies-fund', 'blockchain-strategies-fund-es', 'blockchain-strategies-fund-de', 'blockchain-strategies-fund-fr'].includes(page['slug'])">
    <div style="background-color: whitesmoke;">
        <div class="container p-5">
            <div class="row text-muted justify-content-center" >
                <div class="col-lg-6">
                    <h1 [innerHTML]="convert(page?.modules[0]['title'])"></h1>
                </div>
                <div class="col-lg-6">
                    <h5><strong>{{page?.modules[0]['subtitle']}}</strong></h5>
                    <p class="mt-3" [innerHTML]="convert(page?.modules[0]['description'])"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container p-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 mt-5">
                <img class="p-3" [src]="'/assets/incomeIcon.png'" alt=""
                                    aria-hidden="true">
                <h3>{{ 'FIXED INCOME YIELD' | translate}}</h3>
                <h6 class="text-primary">{{ 'Lending - Staking' | translate}}</h6>
                <p>{{ 'Strategy focusing on lending/borrowing digital' | translate}} <br> {{ 'assets in return for yield.' | translate}}</p>
                <p><strong>{{ 'Benefit : positive monthly returns, low volatility' | translate}}</strong></p>
            </div>
            <!-- <div class="col-lg-6 mt-5">
                <img class="p-3" [src]="'/assets/systematicIcon.png'" alt=""
                                    aria-hidden="true">
                <h3>{{ 'QUANT / SYSTEMATIC TRADING' | translate}}</h3>
                <h6 class="text-primary">{{ 'Directional - Arbitrage & Market neutral' | translate}}</h6>
                <p>{{ 'Strategy focusing on systematic,' | translate}} <br> {{ 'algorithm-based trading signals' | translate}} </p>
                <p><strong>{{ 'Benefit : uncorrelated returns' | translate}}</strong></p>
            </div> -->
            <div class="col-lg-6 mt-5">
                <img class="p-3" [src]="'/assets/passiveIcon.png'" alt=""
                                    aria-hidden="true">
                <h3>{{ 'BETA PASSIVE STRATEGIES' | translate}}</h3>
                <h6 class="text-primary">{{ 'Single - Multi assets' | translate}}</h6>
                <p>{{ 'Strategy focusing on tracking the return' | translate}} <br> {{ 'of a single / basket of digital assets' | translate}}</p>
                <p><strong>{{ 'Benefit : full/direct market participation' | translate}}</strong></p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 mt-3">
                <img class="p-3" [src]="'/assets/fundamentalIcon.png'" alt=""
                                    aria-hidden="true">
                <h3>{{ 'FUNDAMENTAL TRADING' | translate}}</h3>
                <h6 class="text-primary">{{ 'Quantitative / Systematic - Long only' | translate}}</h6>
                <h6 class="text-primary">{{ 'Fundamental - Long / Short Discretionary' | translate}}</h6>
                <p>{{ 'Strategy focusing on active managers with' | translate}} <br> {{ 'a quant or fundamental approach' | translate}} </p>
                <p><strong>{{ 'Benefit : alpha generation' | translate}}</strong></p>
            </div>
            <div class="col-lg-6 mt-3">
                <img class="p-3" [src]="'/assets/privateIcon.png'" alt=""
                                    aria-hidden="true">
                <h3>{{ 'PRIVATE EQUITY -- VENTURE CAPITAL' | translate}}</h3>
                <h6 class="text-primary">{{ 'Early stage projects & tokens' | translate}}</h6>
                <p>{{ 'Strategy focusing on sourcing the most' | translate}} <br> {{ 'attractive companies & projects in the digital asset/blockchain space.' | translate}}</p>
                <p><strong>{{ 'Benefit : potential for greatest returns' | translate}}</strong></p>
            </div>
        </div>
        <!-- <div class="row justify-content-center"> -->
            <!-- <div class="col-lg-6 mt-3">
                <img class="p-3" [src]="'/assets/multiIcon.png'" alt=""
                                    aria-hidden="true">
                <h3>{{ 'MULTI STRATEGY' | translate}}</h3>
                <h6 class="text-primary">{{ 'Diversified' | translate}}</h6>
                <p>{{ 'Strategy focusing on managers' | translate}} <br> {{ 'implementing diversified strategies' | translate}}</p>
                <p><strong>{{ 'Benefit : superior risk / return profile' | translate}}</strong></p>
            </div> -->
        <!-- </div> -->
    </div>
    <div style="background-color: whitesmoke;">
        <div class="container p-5">
            <div class="row text-muted justify-content-center">
                <div class="col-lg-6 mt-5">
                    <h1 [innerHTML]="convert(page?.modules[1]['title'])"></h1>
                </div>
                <div class="col-lg-6 mt-5">
                    <h5><strong>{{page?.modules[1]['subtitle']}}</strong></h5>
                    <p class="mt-3" [innerHTML]="convert(page?.modules[1]['description'])"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container p-5">
        <div class="row mt-3 lg-px-5">
            <div
                [ngClass]="{'col-12 text-center': page?.modules[2].grid == 'twelve', 'col-lg-6 align-self-center p-5': page?.modules[2].grid == 'six'}">
                <h3 class="mb-5">{{page?.modules[2]['title']}}</h3>
            </div>
            <div *ngIf="page?.modules[2].sectionList.length > 0"
                [ngClass]="{'col-12 text-center': page?.modules[2].grid == 'twelve', 'col-lg-6 mt-5': page?.modules[2].grid == 'six'}">
                <div class="row mb-5">
                    <div class="col-lg-6 mb-5" *ngFor="let sectionListItem of page?.modules[2].sectionList">
                        <div class="row text-start">
                            <div class="col-1">
                                <img [src]="'/assets/iconList.png'" width="35px" height="35px" alt=""
                                    aria-hidden="true">
                                <!-- <i class="fa fa-2x fa-check-circle-o" aria-hidden="true"></i> -->
                            </div>
                            <div class="col-11">
                                <h5 class="card-title mx-2">{{sectionListItem['title']}}</h5>
                                <p class="card-text mx-2" [innerHTML]="convert(sectionListItem['description'])"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoaded && !['factsheet', 'about-us','investment-notification-form', 'legal-notices', 'disclaimer', 'privacy-policy', 'sobre-nosotros', 'nota-legal', 'descargo-de-responsabilidad', 'politica-de-privacidad', 'uber-uns', 'haftungsausschluss', 'datenschutz-bestimmungen', 'rechtliche-hinweise', 'a-propos-de-nous', 'notice-legale', 'responsabilite', 'politique-de-confidentialite', 'register', 'registro', 'registrieren', 'es-enregistrer'].includes(page['slug']) && !page['isFeaturedProduct']"
    [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com' + page['image']['url'] + ')', 'height': '80vh',
'background-size': 'cover'}">
    <div class="container pt-15">
        <h1 [innerHTML]="convert(page['title'])"></h1>
        <h4>{{page['subtitle']}}</h4>
        <p class="w-50">{{page['description']}}</p>
    </div>
</div>
<div style="background-color: whitesmoke;"
    *ngIf="isLoaded && ['legal-notices', 'disclaimer', 'privacy-policy', 'nota-legal', 'descargo-de-responsabilidad', 'politica-de-privacidad', 'haftungsausschluss', 'datenschutz-bestimmungen', 'rechtliche-hinweise', 'notice-legale', 'responsabilite', 'politique-de-confidentialite'].includes(page['slug'])">
    <div class="container legal">
        <h1 class="pt-5 text-dark">{{page['title']}}</h1>
        <p class="mt-5 mb-0 pb-5 text-muted legal-h2" [innerHTML]="convert(page['description'])"></p>
    </div>
</div>
<div style="background-color: whitesmoke;"
    *ngIf="isLoaded && ['investment-notification-form'].includes(page['slug'])">
    <div class="container legal">
        <iframe scrolling="no" width="100%" height="2500px" [src]="url" title="Investment Notification Form" frameborder="0"></iframe>
    </div>
</div>
<div *ngIf="isLoaded && ['about-us', 'sobre-nosotros', 'uber-uns', 'a-propos-de-nous'].includes(page['slug']) && !page['isFeaturedProduct']"
    [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com' + page['image']['url'] + ')',
'background-size': 'cover'}">
    <div class="container pt-5">
        <div *ngIf="page?.modules[0].type === 'section' && ['about-us', 'sobre-nosotros', 'uber-uns', 'a-propos-de-nous'].includes(page['slug'])" id="who-we-are">
            <div class="row lg-px-5">
                <div
                    [ngClass]="{'col-12 text-center': page?.modules[0].grid == 'twelve', 'col-lg-6 p-5': page?.modules[0].grid == 'six'}">
                    <!-- [ngClass]="{'col-12 text-center': page?.modules[0].grid == 'twelve', 'col-lg-6 align-self-center p-5': page?.modules[0].grid == 'six'}"> -->
                    <h1>{{page?.modules[0]['title']}}</h1>
                    <h2>{{page?.modules[0]['subtitle']}}</h2>
                    <p class="mt-3" [innerHTML]="convert(page?.modules[0]['description'])"></p>
                </div>
                <div *ngIf="page?.modules[0].sectionList.length > 0"
                    [ngClass]="{'col-12 text-center': page?.modules[0].grid == 'twelve', 'col-lg-6 mt-5': page?.modules[0].grid == 'six'}">
                    <div class="row mb-5">
                        <div class="col-lg-6 mb-5" *ngFor="let sectionListItem of page?.modules[0].sectionList">
                            <div class="row text-start">
                                <div class="col-1">
                                    <img [src]="'/assets/iconList.png'" width="35px" height="35px" alt=""
                                        aria-hidden="true">
                                    <!-- <i class="fa fa-2x fa-check-circle-o" aria-hidden="true"></i> -->
                                </div>
                                <div class="col-11">
                                    <h5 class="card-title mx-2">{{sectionListItem['title']}}</h5>
                                    <p class="card-text mx-2">{{sectionListItem['description']}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngFor="let module of page?.modules"
    [ngStyle]="module.theme == 'light' ? {'background-color': '#0063b1'} : {'background-color': '#00183f'}">
    <div *ngIf="module.type === 'section' && !['about-us', 'sobre-nosotros', 'uber-uns', 'a-propos-de-nous', 'blockchain-strategies-fund', 'blockchain-strategies-fund-es', 'blockchain-strategies-fund-de', 'blockchain-strategies-fund-fr'].includes(page['slug'])" class="pt-5 pb-5 container" id="who-we-are">
        <div class="row lg-px-5">
            <div
                [ngClass]="{'col-12 text-center': module.grid == 'twelve', 'col-lg-6 align-self-center p-5': module.grid == 'six'}">
                <h6>{{module['title']}}</h6>
                <h2>{{module['subtitle']}}</h2>
                <p class="mt-3" [innerHTML]="convert(module['description'])"></p>
            </div>
            <div *ngIf="module.sectionList.length > 0"
                [ngClass]="{'col-12 text-center': module.grid == 'twelve', 'col-lg-6 mt-5': module.grid == 'six'}">
                <div class="row mb-5">
                    <div class="col-lg-6 mb-5" *ngFor="let sectionListItem of module.sectionList">
                        <div class="row text-start">
                            <div class="col-1">
                                <img [src]="'/assets/iconList.png'" width="35px" height="35px" alt=""
                                    aria-hidden="true">
                                <!-- <i class="fa fa-2x fa-check-circle-o" aria-hidden="true"></i> -->
                            </div>
                            <div class="col-11">
                                <h5 class="card-title mx-2">{{sectionListItem['title']}}</h5>
                                <p class="card-text mx-2">{{sectionListItem['description']}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="module.type === 'teams'" class="pt-5 pb-5 container" id="team">
        <!-- <p>{{module['description']}}</p> -->
        <div class="row mb-5">

            <div class="text-center">
                <h6>{{module['title']}}</h6>
                <h2>{{module['subtitle']}}</h2>
            </div>
            <div class="row m-0">
                <div class="container p-5 col-12 col-lg-8 overflow-hidden px-0">
                    <div class="carousel slide" data-ride="carousel">
                        <div class="d-flex">
                            <a class="col-12 col-lg-4" *ngFor="let team of page.teams; let i = index"
                                [ngClass]="{'overlay-1': i==0, 'overlay-2': i==1}" href="javascript:;">
                                <img class="carousel-img opacity-25" [id]="i"
                                    [ngClass]="{'scaled': i==2 && isInit, 'opacity-100': i==2}"
                                    [src]="'https://cms.blockassetmanagement.com'+team['image']['url']" alt="">
                            </a>
                        </div>
                        <a class="carousel-control-prev" (click)="changeTeam('prev')" data-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                        </a>
                        <a class="carousel-control-next" (click)="changeTeam('next')" data-slide="next">
                            <span class="carousel-control-next-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-lg-3 d-lg-block d-none">
                    <h2>{{page.teams[2]['name']}}</h2>
                    <p class="p-2 border border-primary bg-primary fit-content">{{ page.teams[2]['position']}}</p>
                    <p [innerHTML]="convert(page.teams[2]['description'])"></p>
                    <a [href]="page.teams[2]['linkedIn']" target="_blank"><i
                            class="fa fa-lg fa-linkedin p-2 border border-info" aria-hidden="true"></i></a>
                    <a [href]="'mailto:'+page.teams[2]['email']"><i
                            class="fa fa-lg fa-envelope p-2 border border-info mx-2" aria-hidden="true"></i></a>
                </div>
                <div class="d-block d-lg-none">
                    <h2>{{page.teams[0]['name']}}</h2>
                    <p class="p-2 border border-primary bg-primary fit-content">{{ page.teams[0]['position']}}</p>
                    <p [innerHTML]="convert(page.teams[0]['description'])"></p>
                    <a [href]="page.teams[0]['linkedIn']" target="_blank"><i
                            class="fa fa-lg fa-linkedin p-2 border border-info" aria-hidden="true"></i></a>
                    <a [href]="'mailto:'+page.teams[0]['email']"><i
                            class="fa fa-lg fa-envelope p-2 border border-info mx-2" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="module.type === 'awards'" class="pt-5 pb-5 container" id="awards">
        <h6>{{module['title']}}</h6>
        <h2>{{module['subtitle']}}</h2>
        <p>{{module['description']}}</p>
        <div class="row mb-5">
            <div class="row mb-5">
                <div class="col-12 col-lg-6">
                    <button class="border border-info m-2" (click)="filter('all')">{{ 'ALL' | translate}}</button>
                    <button class="border border-info mx-2" (click)="filter('2022')">2022</button>
                    <button class="border border-info mx-2" (click)="filter('2021')">2021</button>
                    <button class="border border-info mx-2" (click)="filter('2020')">2020</button>
                    <button class="border border-info mx-2" (click)="filter('2019')">2019</button>
                </div>
            </div>
            <div class="col-12 col-lg-6 mb-5" *ngFor="let award of page.awards">
                <div class="row">
                    <div class="col-1 fit-content">
                        <img [src]="'/assets/iconList.png'" width="35px" height="35px" alt="" aria-hidden="true">
                        <!-- <i class="fa fa-2x fa-check-circle-o" aria-hidden="true"></i> -->
                    </div>
                    <div class="col-8 col-lg-8">
                        <a [href]="award['link']">
                            <h5 class="card-title">{{award['title']}}</h5>
                        </a>
                    </div>
                    <div class="col-1">
                        <a [href]="award['link']" target="_blank"><i class="fa fa-download mx-3"></i></a>
                    </div>
                </div>
            </div>
            <!--                <div class="col-12 border border-info text-center p-4 h-100 w-100 ">
                    <a [href]="award['link']"><img class="p-4" [src]="'http://blockassetmanagement.hl1034.dinaserver.com'+award['image']['url']"
                            height="100%" alt=""></a>
                    <p class="text-primary">{{award['title']}}</p>
                </div>-->
        </div>
    </div>
    <!-- <div *ngIf="module.type === 'documents'" class="pt-5 pb-5 container" [id]="module['title'] == 'Montlhy Factsheet' ? 'factsheets' : 'presentations'">
        <h6>{{module['title']}}</h6>
        <h2>{{module['subtitle']}}</h2>
        <p>{{module['description']}}</p>
        <div class="row mb-5">
            <div class="row mb-5" *ngFor="let document of module.documents">
                <div class="col-1 fit-content">
                    <img [src]="'/assets/iconList.png'" width="35px" height="35px" alt="" aria-hidden="true">
                </div>
                <div class="col-8 col-lg-4">
                    <a [href]="API_URL + document['url']">
                        <h5 class="card-title">{{document['name']}}</h5>
                    </a>
                </div>
                <div class="col-1">
                    <a [href]="API_URL + document['url']" target="_blank"><i class="fa fa-download mx-3"></i></a>
                </div>
            </div>
        </div>
    </div> -->
</div>
<div *ngIf="isLoaded && ['factsheet'].includes(page['slug']) && !page['isFeaturedProduct']">
    <div class="pb-5"
    [ngStyle]="{'background-image': 'url(https://cms.blockassetmanagement.com' + page['image']['url'] + ')',
    'background-size': 'cover'}">
        <div class="container p-5">
            <div class="row">
                <div *ngFor="let module of page?.modules"  class="col-lg-6 col-12">
                    <div *ngIf="module.type === 'documents'" [id]="module['title'] == 'Montlhy Factsheet' ? 'factsheets' : 'presentations'">
                        <h3>{{module['title']}}</h3>
                        <h2>{{module['subtitle']}}</h2>
                        <h2>{{module['description']}}</h2>
                        <div class="row mt-5 mb-5">
                            <div class="row mb-5" *ngFor="let document of module.documents">
                                <div class="col-1 fit-content">
                                    <img [src]="'/assets/iconList.png'" width="35px" height="35px" alt="" aria-hidden="true">
                                </div>
                                <div class="col-8 col-lg-8">
                                    <a [href]="API_URL + document['url']">
                                        <h5 class="card-title">{{document['name']}}</h5>
                                    </a>
                                </div>
                                <div class="col-1">
                                    <a [href]="API_URL + document['url']" target="_blank"><i class="fa fa-download mx-3"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container p-5">
        <div class="text-center">
            <h3>{{ 'INVESTMENT NOTIFICATION FORM' | translate}}</h3>
            <p>
                {{ 'To submit your Investment Notification Form (INF) click here' | translate}}
            </p>                
            <button *ngIf="translate.currentLang === 'en'" class="bg-primary border-primary fit-content p-3"><a
                [href]="translate.currentLang + '/user/investment-notification-form'">GO TO FORM</a></button>
            <button *ngIf="translate.currentLang === 'es'" class="bg-primary border-primary fit-content p-3"><a
                [href]="translate.currentLang + '/user/formulario-notificacion-inversion'">IR AL FORMULARIO</a></button>
            <button *ngIf="translate.currentLang === 'de'" class="bg-primary border-primary fit-content p-3"><a
                [href]="translate.currentLang + '/user/anlagemitteilungsformular'">ZUM FORMULAR GEHEN</a></button>
            <button *ngIf="translate.currentLang === 'fr'" class="bg-primary border-primary fit-content p-3"><a
                [href]="translate.currentLang + '/user/formulaire-de-notification-de-investissement'">ALLER AU FORMULAIRE</a></button>
        </div>
    </div>
    
</div>
<div *ngIf="!isLoaded && notFound">
    <div class="main-panel w-100">
        <div class="content-wrapper">
          <div class="h-100">
            <app-not-found></app-not-found>
          </div>
        </div>
      </div>
</div>