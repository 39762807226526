import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CoreService } from 'src/app/shared/services/core.service';
import * as marked from "marked";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  newsletterForm!: FormGroup;
  dynamicTitle = {'title': '', 'index': 0};
  home;
  products;
  intervalId;
  intervalTitleId;
  awards;
  news;
  newsResearch;
  newsletter;
  newsInsight;
  currentAward = 0;
  customList = [];
  isInit = true;
  notFound = false;

  constructor(
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    public translate: TranslateService,
  ) {
    this.createForm();
  }

  ngOnInit(): void {

    this.coreService.getHome(this.translate.currentLang).subscribe(home => {
      this.home = home;
      this.dynamicTitle['title'] = home['mainTitle'];
      // let titlteFont = document.getElementById('dynamicTitle').firstChild.;
      // titlteFont.style.fontFamily = 'gothammedium';
      this.intervalTitleId = setInterval(() => {
        this.changeDynamicTitle()
      }, 5000);
      this.titleService.setTitle(this.home['meta']['title']);
      this.metaService.addTags(
        [
          { property: 'og:title', content: this.home['meta']['og_title'] },
          { name: 'description', content: this.home['meta']['description'] },
          { property: 'og:description', content: this.home['meta']['og_description'] },
          { property: 'og:image', content: 'https://cms.blockassetmanagement.com' + this.home['meta']['og_image']['url'] }
        ]
      );
        var content = marked(home['aboutClaim']);
        var domParser = new DOMParser();
        var docElement = domParser.parseFromString(content, "text/html").documentElement;
        var custom = docElement.getElementsByTagName("h3");
        for (var i = 0; i < custom.length; i++) {
          this.customList[i]['title'] = custom['innerText'];
          for (var j = 0; j < custom['nextElementSibling']['children'].length; j++) {
            this.customList[i][j]['description'] = custom['nextElementSibling']['children'][j]['innerText'];
          }
      }
    });
    this.coreService.getProductsPages(this.translate.currentLang).subscribe(products => {
      this.products = products;
    });
    this.coreService.getNewsResearch(this.translate.currentLang, '&isFeatured=true', 0, 1).subscribe(newsResearch => {
      this.newsResearch = newsResearch;
    });
    this.coreService.getNewsInsight(this.translate.currentLang, '&isFeatured=true', 0, 1).subscribe(newsInsight => {
      this.newsInsight = newsInsight;
    });
    this.coreService.getNews(this.translate.currentLang, '&isFeatured=true', 0, 2).subscribe(news => {
      this.news = news;
    });
    this.coreService.getAwards().subscribe(awards => {
      this.awards = awards;
      this.intervalId = setInterval(() => {
        this.isInit = false;
        this.arraymove(this.awards, 0, this.awards.length);
        if (this.currentAward == this.awards.length) {
          this.currentAward = 0;
        } else {
          this.currentAward++;
        }
        setTimeout(() => {
          Array.from(document.querySelectorAll('.scaled')).forEach(function (el) {
            el.classList.remove('scaled');
          });
          let someElement = document.getElementById("1");
          if (someElement)
            someElement.className += " scaled";
        }, 100);
      }, 8000);
    });
    this.coreService.getNewsletter(this.translate.currentLang).subscribe(newsletter => {
      this.newsletter = newsletter;
    });
    if (this.router.url.split('/')[1] !== '' && this.router.url.split('/')[1] !== 'en' && this.router.url.split('/')[1] !== 'es' && this.router.url.split('/')[1] !== 'de' && this.router.url.split('/')[1] !== 'fr') {
      this.notFound = true;
    }
    // document.getElementById('modalButton').click();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.intervalTitleId) {
      clearInterval(this.intervalTitleId);
    }
  }

  createForm() {
    this.newsletterForm = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      email: ['',
        [Validators.required, Validators.email]
      ],
      privacyPolicy: [,
        Validators.required
      ],
      accessDate: [true,
        Validators.required
      ],
      marketingDate: [true,
        Validators.required
      ],
      profilingDate: [true,
        Validators.required
      ],
    });
  }

  changeDynamicTitle() {
    let dynamicElement = document.getElementById('dynamicTitle');
    let title;
    switch (this.dynamicTitle['index']) {
      case 0:
        dynamicElement.style.opacity =  "0";
        title = this.translate.instant("EXPERIENCE - 5 YEAR TRACK RECORD");
        setTimeout(() => {
          this.dynamicTitle['title'] = title;
          this.dynamicTitle['index'] = 1;
          dynamicElement.style.opacity =  "1";   
        }, 1000);
        break;
      case 1:
        dynamicElement.style.opacity =  "0";
        title = this.translate.instant("INSTITUTIONAL GRADE DUE DILIGENCE");
        setTimeout(() => {
          this.dynamicTitle['title'] = title;
          this.dynamicTitle['index'] = 2;
          dynamicElement.style.opacity =  "1"; 
        }, 1000);
        break;
      case 2:
        dynamicElement.style.opacity =  "0";
        title = this.translate.instant("UNRIVALLED DIVERSIFICATION");
        setTimeout(() => {
          this.dynamicTitle['title'] = title;
          this.dynamicTitle['index'] = 3;
          dynamicElement.style.opacity =  "1"; 
        }, 1000);
        break;
      case 3:
        dynamicElement.style.opacity =  "0";
        title = this.translate.instant("BEST IN CLASS");
        setTimeout(() => {
          this.dynamicTitle['title'] = title;
          this.dynamicTitle['index'] = 4;
          dynamicElement.style.opacity =  "1"; 
        }, 1000);
        break;
      case 4:
        dynamicElement.style.opacity =  "0";
        title = this.translate.instant("MULTI AWARD WINNING");
        setTimeout(() => {
          this.dynamicTitle['title'] = title;
          this.dynamicTitle['index'] = 5;
          dynamicElement.style.opacity =  "1"; 
        }, 1000);
        break;
      case 5:
        dynamicElement.style.opacity =  "0";
        title = this.translate.instant("MONTHLY LIQUIDITY");
        setTimeout(() => {
          this.dynamicTitle['title'] = title;
          this.dynamicTitle['index'] = 6;
          dynamicElement.style.opacity =  "1"; 
        }, 1000);
        break;
      case 6:
        dynamicElement.style.opacity =  "0";
        title = this.translate.instant("NO LEVERAGE");
        setTimeout(() => {
          this.dynamicTitle['title'] = title;
          this.dynamicTitle['index'] = 7;
          dynamicElement.style.opacity =  "1"; 
        }, 1000);
        break;
      case 7:
        dynamicElement.style.opacity =  "0";
        setTimeout(() => {
          this.dynamicTitle['title'] = this.home['mainTitle'];;
          this.dynamicTitle['index'] = 0;
          dynamicElement.style.opacity =  "1";
        }, 1000);
        break;
      default:
        break;
    }
  }

  changeAward(position) {
    this.isInit = false;
    position == 'next' ? this.arraymove(this.awards, 0, this.awards.length) : this.arraymove(this.awards, this.awards.length - 1, 0);
    if (this.currentAward == this.awards.length && position === 'next') {
      this.currentAward = 0;
    } else if (this.currentAward == 0 && position === 'prev') {
      this.currentAward = this.awards.length
    } else {
      position === 'next' ? this.currentAward++ : this.currentAward--;
    }

    setTimeout(() => {
      Array.from(document.querySelectorAll('.scaled')).forEach(function (el) {
        el.classList.remove('scaled');
      });
      let someElement = document.getElementById("1");
      if (someElement)
        someElement.className += " scaled";
    }, 100);
  }

  // convert(content) {
  //   return marked(content);
  // }

  convert(content) {
    return document.getElementById('dynamicTitle').innerHTML = content;
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  sendNewsletter() {
    if (this.newsletterForm.invalid) {
      return;
    }

    var currentDate = new Date();

    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //Be careful! January is 0 not 1
    var year = currentDate.getFullYear();

    var dateString = ('0' + date).slice(-2) + "/" + ('0' + (month + 1)).slice(-2) + "/" + year;

    const newsletter = {
      name: this.newsletterForm.get('name')!.value,
      email: this.newsletterForm.get('email')!.value,
      privacyPolicy: this.newsletterForm.get('privacyPolicy')!.value,
      accessDate: dateString,
      marketingDate: dateString,
      profilingDate: dateString
    };

    this.coreService.postNewsletter(newsletter).subscribe((result) => {
      this.router.navigate(['thanks'], { relativeTo: this.route });
    }, (error) => {
    });
  }
}
