import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/shared/services/core.service';
import { environment } from '../../../environments/environment';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuItems;
  socialMedia = [];
  public activeLang = 'es';
  user;
  userPages;

  constructor(
    private coreService: CoreService,
    private location: Location,
    private router: Router,
    public translate: TranslateService
  ) {
    this.activeLang = location.path().substring(1, 3);
    if (this.activeLang === 'es' || this.activeLang === 'en' || this.activeLang === 'de' || this.activeLang === 'fr') {
      this.translate.use(this.activeLang);
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit(): void {
    this.coreService.getMenu(this.translate.currentLang).subscribe(items => {
      this.menuItems = items.page;
    });
    this.coreService.getSocialMedia().subscribe(result => {
      this.socialMedia = result.socialMedia;
    });
    this.coreService.getUserPages(this.translate.currentLang).subscribe(pages => {
      this.userPages = pages;
    });
    this.user = localStorage.getItem('bam_user');
  }

  isArrayEmpty(array) {
    return array.length == 0;
  }

  public changeLanguage(lang) {
    window.open(FRONT_URL + '/' + lang, '_self');
    // console.log(this.router.url.split('/')[1]);
    // if (lang === this.router.url.split('/')[1]) {
    //   return;
    // } else {
    //   this.activeLang = lang;
    //   this.translate.use(lang);
    //   this.translate.setDefaultLang(lang);
    //   setTimeout(() => {
    //     let routes = this.router.url.split('/');
    //     if (routes.length > 3) {
    //       this.coreService.getNewsItem(lang === 'es' ? 'en' : 'es', routes[3]).subscribe(newItem => {
    //         this.coreService.getNewsItemById(lang, newItem[0]['localizations'][0]['id']).subscribe(newItem2 => {
    //           window.open(FRONT_URL + '/' + lang + '/' + this.translate.instant(routes[2]) + '/' + newItem2[0]['slug'], '_self');
    //         })
    //       })
    //     } else if (routes.length > 2) {
    //       if (!routes[2].includes('?')) {
    //         this.coreService.getPage(lang, routes[2]).subscribe(page => {
    //           this.coreService.getPageById(page[0]['localizations'][0]['id']).subscribe(pageById => {
    //               window.open(FRONT_URL + '/' + lang + '/' + pageById[0]['slug'], '_self');
    //           })
    //         })
    //       } else {
    //         let subRoutes = routes[2].split('?');
    //         window.open(FRONT_URL + '/' + lang + '/' + this.translate.instant(subRoutes[0]) + '?' + subRoutes[1], '_self');
    //         console.log(subRoutes);
    //       }
          
    //     } else {
    //       window.open(FRONT_URL + '/' + lang, '_self');
    //     }
    //   }, 1000);
    // }
    //window.open(`/${lang}${this.router.url.substring(3)}`, '_self');
  }

  logout() {
    localStorage.clear();
    window.open(FRONT_URL + '/' + this.translate.currentLang, '_self');
  }
}
