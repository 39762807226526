<div class="container">
    <h1 class="text-center mt-5">{{ 'CONTACT US' | translate}}</h1>
    <div class="row mb-5 pb-5">
        <div class="col-lg-6 mt-5 px-5">
            <form [formGroup]="contactForm" class="p-5 border border-info">
                <div class="form-group">
                    <label for="">{{ 'Your Name' | translate}}</label>
                    <input type="text" placeholder="{{ 'Name' | translate}}" formControlName="name" class="form-control text-primary">
                </div>
                <div class="form-group mt-3">
                    <label for="">{{ 'Your Email' | translate}}</label>
                    <input type="text" placeholder="Email" formControlName="email" class="form-control text-primary">
                </div>
                <div class="form-group mt-3">
                    <label for="">{{ 'Question' | translate}}</label>
                    <textarea placeholder="{{ 'Type Your Question Here' | translate}}" formControlName="question" class="form-control text-primary"></textarea>
                </div>
                <div class="form-group">
                    <input class="check-control" type="checkbox" formControlName="privacyPolicy">{{ ' I accept the privacy policy and give my consent for future communications and marketing actions.*' | translate}}
                </div>
                <div class="text-end">
                    <button class="bg-primary border-primary mt-5 p-0 px-3" (click)="sendContact()">{{ 'SUBMIT' | translate}}</button>
                </div>
              <small class="text-success" *ngIf="alert.type == 1">
                {{alert.message}}
              </small>
              <small class="text-danger" *ngIf="alert.type == 2">
                {{alert.message}}
              </small>
            </form>
        </div>
        <div class="col-lg-6 px-5">
            <div class="text-center">
                <img src="/assets/particles.png" width="20%" alt="">
            </div>
            <h3>
                {{ 'SEND US AN EMAIL, WE ENDEAVOUR' | translate}}
            </h3>
            <h5>{{ 'TO ANSWER ALL INQUIRIES WITHIN' | translate}} <span class="text-primary">24H</span></h5>
            <div class="social mt-5">
                <div>
                    <img src="/assets/email.png" height="10%" width="10%" alt="Email">
                    <div><a href="mailto:info@blockassetmanagement.com" class="vertical-sub">Email ID<p>info@blockassetmanagement.com</p></a>
                    </div>
                </div>
                <div>
                    <img src="/assets/twitter.png" height="10%" width="10%" alt="Twitter"><a href="https://twitter.com/intent/follow?original_referer=http%3A%2F%2Fblockassetmanagement.com%2F&ref_src=twsrc%5Etfw&region=follow_link&screen_name=blockassetmgmt&tw_p=followbutton"
                        target="_blank" class="align-self-center">Twitter</a>
                </div>
                <div>
                    <img src="/assets/linkedIn.png" height="10%" width="10%" alt="LinkedIn"><a href="https://www.linkedin.com/company/27060700/"
                        target="_blank" class="align-self-center">LinkedIn</a>
                </div>
                <!-- <div class="mt-3">
                    <i class="fa fa-2x fa-twitter text-primary mx-3" aria-hidden="true"></i><a href="https://twitter.com/intent/follow?original_referer=http%3A%2F%2Fblockassetmanagement.com%2F&ref_src=twsrc%5Etfw&region=follow_link&screen_name=blockassetmgmt&tw_p=followbutton"
                        target="_blank" class="text-primary">Follow us in Twitter</a>
                </div>
                <div class="mt-3">
                    <i class="fa fa-2x fa-linkedin text-primary mx-3" aria-hidden="true"></i><a href="https://www.linkedin.com/company/27060700/"
                        target="_blank" class="text-primary">Follow us in LinkedIn</a>
                </div> -->
            </div>
        </div>
    </div>
</div>
