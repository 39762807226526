<div class="mt-5">
    <div *ngIf="page && isLoaded" class="container mb-5">
        <div class="row mb-5">
            <div class="col-12 col-lg-8">
                <img [src]="'https://cms.blockassetmanagement.com' + page['image']['url']" alt="" width="95%">
                <!-- AddToAny BEGIN -->
                <div class="a2a_kit a2a_kit_size_32 a2a_default_style mt-2">
                    <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
                    <!-- <a class="a2a_button_facebook"></a> -->
                    <a class="a2a_button_twitter"></a>
                    <a class="a2a_button_email"></a>
                    <!-- <a class="a2a_button_whatsapp"></a> -->
                    <a class="a2a_button_linkedin"></a>
                </div>
                    <!-- AddToAny END -->
                <p class="text-primary mt-3">{{page['updated_at'] | date: 'dd/MM/YYYY'}}</p>
                <h1>{{page['title']}}</h1>
                <h4>{{page['subtitle']}}</h4>
                <div *ngIf="page['categories'][0]['name'] === 'News'"
                    class="bg-primary border border-primary p-2 fit-content">
                    <a *ngIf="translate.currentLang === 'en'" class="text-white"
                        [href]=" 'en/resources?category=news'">News</a>
                    <a *ngIf="translate.currentLang === 'es'" class="text-white"
                        [href]=" 'es/recursos?category=news'">News</a>
                    <a *ngIf="translate.currentLang === 'de'" class="text-white"
                        [href]=" 'de/ressourcen?category=news'">News</a>
                    <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                        [href]=" 'fr/ressources?category=news'">News</a>
                </div>
                <div *ngIf="page['categories'][0]['name'] === 'Research'"
                    class="research p-2 fit-content mb-4">
                    <a *ngIf="translate.currentLang === 'en'" class="text-white"
                        [href]="'en/resources?category=research'">Research</a>
                    <a *ngIf="translate.currentLang === 'es'" class="text-white"
                        [href]="'es/recursos?category=research'">Research</a>
                    <a *ngIf="translate.currentLang === 'de'" class="text-white"
                        [href]="'de/ressourcen?category=research'">Research</a>
                    <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                        [href]="'fr/ressources?category=research'">Research</a>
                </div>
                <div *ngIf="page['categories'][0]['name'] === 'Insights'" class="insights p-2 fit-content mb-4">
                    <a *ngIf="translate.currentLang === 'en'" class="text-white"
                        [href]="'en/resources?category=insights'">Insights</a>
                    <a *ngIf="translate.currentLang === 'es'" class="text-white"
                        [href]="'es/recursos?category=insights'">Insights</a>
                    <a *ngIf="translate.currentLang === 'de'" class="text-white"
                        [href]="'de/ressourcen?category=insights'">Insights</a>
                    <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                        [href]="'fr/ressources?category=insights'">Insights</a>
                </div>
                <p class="mt-3 pr-4" [innerHTML]="convert(page['content'])"></p>
                <iframe *ngIf="page['video']" width="100%" height="450" class="pr-4" [src]="dom.bypassSecurityTrustResourceUrl(page['video'])" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-lg-4">
                <div class="justify-content-around row p-3 border border-info mb-5">
                    <div class="col-12 text-center">
                        <h4>{{ 'SUBSCRIBE TO' | translate}}</h4>
                        <h2>{{ 'OUR NEWSLETTER' | translate}}</h2>
                    </div>
                    <div class="col-12">
                        <form [formGroup]="newsletterForm" id="myForm">
                            <div class="mb-1 px-5">
                                <input class="form-control text-primary" type="text" formControlName="name" placeholder="{{ 'Name' | translate}}">
                            </div>
                            <div class="mb-1 px-5">
                                <input class="form-control text-primary" type="email" formControlName="email" placeholder="Email">
                            </div>
                            <div class="form-group">
                                <input class="check-control" type="checkbox" formControlName="privacyPolicy">{{ ' I accept the privacy policy and give my consent for future communications and marketing actions.*' | translate}}
                            </div>
                            <!-- <div class="form-group">
                                <input class="check-control" type="checkbox" formControlName="accessDate"> Permission to access the service *
                            </div>
                            <div class="form-group">
                                <input class="check-control" type="checkbox" formControlName="marketingDate"> Consent for marketing activities *
                            </div>
                            <div class="form-group mb-1">
                                <input class="check-control" type="checkbox" formControlName="profilingDate"> Permission for data profiling *
                            </div> -->
                            <div class="text-center">
                                <button class="bg-primary border-primary pt-1 pb-1 button-subscribe" (click)="sendNewsletter()"><a
                                        [href]="">{{ 'SUBSCRIBE' | translate}}</a></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="justify-content-around row p-4 border border-info">
                    <div class="col-12 d-flex">
                        <img [src]="'/assets/iconList.png'" width="35px" height="35px" alt="" aria-hidden="true">
                        <h4>{{ 'RECENT NEWS' | translate}}</h4>
                    </div>
                    <div *ngFor="let newsItem of news">
                        <div class="col-12 mt-3">
                            <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources/'+newsItem['slug']" class="text-white"><p>{{newsItem['extract']}}</p></a>
                            <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos/'+newsItem['slug']" class="text-white"><p>{{newsItem['extract']}}</p></a>
                            <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen/'+newsItem['slug']" class="text-white"><p>{{newsItem['extract']}}</p></a>
                            <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources/'+newsItem['slug']" class="text-white"><p>{{newsItem['extract']}}</p></a>
                        </div>
                        <div class="col-12 d-flex mt-3">
                            <p class="text-primary mt-3">{{newsItem['updated_at'] | date: 'dd/MM/YYYY'}}</p>
                            <div *ngIf="newsItem['categories'][0]['name'] === 'News'"
                                class="news p-2 fit-content mx-3">
                                <a *ngIf="translate.currentLang === 'en'"  class="text-white"
                                    [href]="'en/resources?category=news'">News</a>
                                <a *ngIf="translate.currentLang === 'es'"  class="text-white"
                                    [href]="'es/recursos?category=news'">News</a>
                                <a *ngIf="translate.currentLang === 'de'"  class="text-white"
                                    [href]="'de/ressourcen?category=news'">News</a>
                                <a *ngIf="translate.currentLang === 'fr'"  class="text-white"
                                    [href]="'fr/ressources?category=news'">News</a>
                                </div>
                            <div *ngIf="newsItem['categories'][0]['name'] === 'Research'"
                                class="research p-2 fit-content mx-3">
                                <a *ngIf="translate.currentLang === 'en'" class="text-white"
                                    [href]="'en/resources?category=research'">Research</a>
                                <a *ngIf="translate.currentLang === 'es'" class="text-white"
                                    [href]="'es/recursos?category=research'">Research</a>
                                <a *ngIf="translate.currentLang === 'de'" class="text-white"
                                    [href]="'de/ressourcen?category=research'">Research</a>
                                <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                                    [href]="'fr/ressources?category=research'">Research</a>
                                </div>
                            <div *ngIf="newsItem['categories'][0]['name'] === 'Insights'"
                                class="insights p-2 fit-content mx-3">
                                <a *ngIf="translate.currentLang === 'en'" class="text-white" 
                                    [href]="'en/resources?category=insights'">Insights</a>
                                <a *ngIf="translate.currentLang === 'es'" class="text-white" 
                                    [href]="'es/recursos?category=insights'">Insights</a>
                                <a *ngIf="translate.currentLang === 'de'" class="text-white" 
                                    [href]="'de/ressourcen?category=insights'">Insights</a>
                                <a *ngIf="translate.currentLang === 'fr'" class="text-white" 
                                    [href]="'fr/ressources?category=insights'">Insights</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="blog && isLoaded" class="container">
        <div class="text-center">
            <h1 class="mb-5">{{ 'RESOURCES' | translate}}</h1>
            <!-- <h1 class="mb-5">{{title}}</h1> -->
            <p>{{ 'Block Asset Management was created in 2017 by a group of friends from the traditional financial sector that shared a common interest in Blockchain technology and the belief that digital currencies and the blockchain technology behind them would revolutionise the current financial & economic system. It proved to be just the tip of the iceberg!' | translate}}
            </p>
        </div>
        <div class="p-5">
            <div class="test justify-content-around row p-5 border border-info">
                <div class="col-12 col-lg-4 mt-4">
                    <h4>{{ 'SUBSCRIBE TO' | translate}}</h4>
                    <h2>{{ 'OUR NEWSLETTER' | translate}}</h2>
                </div>
                <div class="col-12 col-lg-4">
                    <form [formGroup]="newsletterForm" id="myForm">
                        <div class="mb-1 form-group">
                            <input class="form-control text-primary" type="text" formControlName="name" placeholder="{{ 'Name' | translate}}">
                        </div>
                        <div class="mb-1 form-group">
                            <input class="form-control text-primary" type="email" formControlName="email" placeholder="Email">
                        </div>
                        <div class="form-group">
                            <input class="check-control" type="checkbox" formControlName="privacyPolicy">{{ ' I accept the privacy policy and give my consent for future communications and marketing actions.*' | translate}}
                        </div>
                        <!-- <div class="form-group">
                            <input class="check-control" type="checkbox" formControlName="accessDate"> Permission to access the service *
                        </div>
                        <div class="form-group">
                            <input class="check-control" type="checkbox" formControlName="marketingDate"> Consent for marketing activities *
                        </div>
                        <div class="form-group mb-1">
                            <input class="check-control" type="checkbox" formControlName="profilingDate"> Permission for data profiling *
                        </div> -->
                        <div class="text-end">
                            <button class="bg-primary border-primary pt-1 pb-1 button-subscribe" (click)="sendNewsletter()"><a
                                    [href]="">{{ 'SUBSCRIBE' | translate}}</a></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="container d-lg-flex justify-content-center mb-5">
            <button class="news px-5 p-3 fit-content mb-3 m-lg-3 w-100">
                <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources?category=news'">NEWS</a>
                <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos?category=news'">NEWS</a>
                <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen?category=news'">NEWS</a>
                <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources?category=news'">NEWS</a>
            </button>
            <button class="research px-5 p-3 fit-content mb-3 m-lg-3 w-100">
                <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources?category=research'">RESEARCH</a>
                <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos?category=research'">RESEARCH</a>
                <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen?category=research'">RESEARCH</a>
                <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources?category=research'">RESEARCH</a>
            </button>
            <button class="insights fit-content px-5 p-3 mb-3 m-lg-3 w-100">
                <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources?category=insights'">INSIGHTS</a>
                <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos?category=insights'">INSIGHTS</a>
                <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen?category=insights'">INSIGHTS</a>
                <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources?category=insights'">INSIGHTS</a>
            </button>
        </div>
        <div class="row">
            <div *ngFor="let newsItem of news" class="col-lg-4 mb-3">
                <div class="h-100 w-100 col-12" [ngStyle]="{'background-image': 'url(' + getImage(newsItem['categories'][0]['name']) + ')',
                'background-size': 'cover'}">
                    <div class="card-body row p-5">
                        <div class="mt-5">
                            <div *ngIf="newsItem['categories'][0]['name'] === 'News'"
                                class="news p-2 fit-content mb-4">
                                <a *ngIf="translate.currentLang === 'en'" class="text-white"
                                    [href]="'en/resources?category=news'">News</a>
                                <a *ngIf="translate.currentLang === 'es'" class="text-white"
                                    [href]="'es/recursos?category=news'">News</a>
                                <a *ngIf="translate.currentLang === 'de'" class="text-white"
                                    [href]="'de/ressourcen?category=news'">News</a>
                                <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                                    [href]="'fr/ressources?category=news'">News</a>
                            </div>
                            <div *ngIf="newsItem['categories'][0]['name'] === 'Research'"
                                class="research p-2 fit-content mb-4">
                                <a *ngIf="translate.currentLang === 'en'" class="text-white"
                                    [href]="'en/resources?category=research'">Research</a>
                                <a *ngIf="translate.currentLang === 'es'" class="text-white"
                                    [href]="'es/recursos?category=research'">Research</a>
                                <a *ngIf="translate.currentLang === 'de'" class="text-white"
                                    [href]="'de/ressourcen?category=research'">Research</a>
                                <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                                    [href]="'fr/ressources?category=research'">Research</a>
                            </div>
                            <div *ngIf="newsItem['categories'][0]['name'] === 'Insights'"
                                class="insights p-2 fit-content mb-4">
                                <a *ngIf="translate.currentLang === 'en'" class="text-white"
                                    [href]="'en/resources?category=insights'">Insights</a>
                                <a *ngIf="translate.currentLang === 'es'" class="text-white"
                                    [href]="'es/recursos?category=insights'">Insights</a>
                                <a *ngIf="translate.currentLang === 'de'" class="text-white"
                                    [href]="'de/ressourcen?category=insights'">Insights</a>
                                <a *ngIf="translate.currentLang === 'fr'" class="text-white"
                                    [href]="'fr/resources?category=insights'">Insights</a>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h4 class="card-title">{{newsItem['title']}}</h4>
                            <p class="card-text">{{newsItem['extract']}}</p>
                            <a *ngIf="translate.currentLang === 'en'" [href]="'en/resources/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                            <a *ngIf="translate.currentLang === 'es'" [href]="'es/recursos/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                            <a *ngIf="translate.currentLang === 'de'" [href]="'de/ressourcen/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                            <a *ngIf="translate.currentLang === 'fr'" [href]="'fr/ressources/'+newsItem['slug']">{{ 'Learn More' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination justify-content-center m-lg-5">
                <button (click)="changePage(currentPage-1)"><a>&#60;</a></button>
                <button *ngFor="let page of [].constructor(pages), let i = index" (click)="changePage(i)"><a>{{i+1}}</a></button>
                <button (click)="changePage(currentPage+1)"><a>></a></button>
            </div>
        </div>
    </div>
</div>
