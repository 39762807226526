import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    private http: HttpClient
  ) { }

  public getHome(language): Observable<any> {
    return this.http
      .get<any>(API_URL + '/home?_locale=' + language);
  }

  public getPage(language, page): Observable<any> {
    return this.http
      .get<any>(API_URL + `/pages?slug=${page}`);
  }

  public getPageById(id): Observable<any> {
    return this.http
      .get<any>(API_URL + `/pages?id=${id}`);
  }

  public getProductsPages(language): Observable<any> {
    return this.http
      .get<any>(API_URL + `/pages?isFeaturedProduct=true&_locale=${language}`);
  }

  public getNewsItem(language, title): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news?_locale=${language}&slug=${title}`);
  }

  public getNewsItemById(language, id): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news?_locale=${language}&id=${id}`);
  }

  public getRecentNews(language): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news?_limit=4&_sort=created_at:DESC&_locale=${language}`);
  }

  public getAllNews(language, start, limit): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news?_start=${start}&_limit=${limit}&_sort=created_at:DESC&_locale=${language}`);
  }

  public getAllNewsCount(): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news/count`);
  }

  public getNews(language, isFeatured, start, limit): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news?categories.name=news${isFeatured}&_start=${start}&_limit=${limit}&_sort=created_at:DESC&_locale=${language}`);
  }

  public getNewsCount(): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news/count?categories.name=news`);
  }

  public getNewsResearch(language, isFeatured, start, limit): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news?categories.name=research${isFeatured}&_start=${start}&_limit=${limit}&_sort=created_at:DESC&_locale=${language}`);
  }

  public getNewsResearchCount(): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news/count?categories.name=research`);
  }

  public getNewsInsight(language, isFeatured, start, limit): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news?categories.name=insights${isFeatured}&_start=${start}&_limit=${limit}&_sort=created_at:DESC&_locale=${language}`);
  }

  public getNewsInsightCount(): Observable<any> {
    return this.http
      .get<any>(API_URL + `/news/count?categories.name=insights`);
  }

  public getAwards(): Observable<any> {
    return this.http
      .get<any>(API_URL + '/awards?isFeatured=true');
  }

  public getAwardsByYear(year): Observable<any> {
    return this.http
      .get<any>(API_URL + '/awards?_sort=year:DESC' + year);
  }

  public getTeams(): Observable<any> {
    return this.http
      .get<any>(API_URL + '/teams');
  }

  public getMenu(language): Observable<any> {
    return this.http
      .get<any>(API_URL + '/menu?_locale=' + language);
  }

  public getNewsletter(language): Observable<any> {
    return this.http
      .get<any>(API_URL + '/newsletter?_locale=' + language);
  }

  public getFooter(language): Observable<any> {
    return this.http
      .get<any>(API_URL + '/footer?_locale=' + language);
  }

  public getSocialMedia(): Observable<any> {
    return this.http
      .get<any>(API_URL + '/social-media/');
  }

  public sendContact(contact): Observable<any> {
    return this.http
      .post<any>(API_URL + '/email', contact);
  }

  public postContact(contact): Observable<any> {
    return this.http
      .post<any>('https://hooks.zapier.com/hooks/catch/8831384/bdwpbxs', contact, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  }

  public postUser(user): Observable<any> {
    return this.http
      .post<any>(API_URL + '/auth/local/register', user);
  }

  public getUser(id): Observable<any> {
    return this.http
      .get<any>(API_URL + '/users/' + id, {
        headers: {
          'Authorization': 'Bearer ' + localStorage['bam_jwt']
        }
      });
  }

  public putUser(id, user): Observable<any> {
    return this.http
      .put<any>(API_URL + '/users/' + id, user,  {
        headers: {
          'Authorization': 'Bearer ' + localStorage['bam_jwt']
        }
      });
  }

  public getUserPages(language): Observable<any> {
    return this.http
      .get<any>(API_URL + `/pages?isPrivate=true`);
  }

  public postLogin(user): Observable<any> {
    return this.http
      .post<any>(API_URL + '/auth/local', user);
  }

  public postRegister(register): Observable<any> {
    return this.http
      .post<any>('https://hooks.zapier.com/hooks/catch/8831384/bd8crj6', register, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  }

  public postNewsletter(newsletter): Observable<any> {
    return this.http
      .post<any>('https://hooks.zapier.com/hooks/catch/8831384/bd6xxop', newsletter, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  }

  public postNotificationForm(notificationForm): Observable<any> {
    return this.http
      .post<any>('https://hooks.zapier.com/hooks/catch/8831384/bqp2lfr', notificationForm, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  }

  public forgotPassword(user): Observable<any> {
    return this.http
      .post<any>(API_URL + '/auth/forgot-password', user);
  }

  public changePassword(user): Observable<any> {
    return this.http
      .post<any>(API_URL + '/auth/reset-password', user);
  }
}
