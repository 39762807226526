import { Component, OnInit } from '@angular/core';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/shared/services/core.service';
import * as marked from "marked";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dynamic-blog',
  templateUrl: './dynamic-blog.component.html',
  styleUrls: ['./dynamic-blog.component.scss']
})
export class DynamicBlogComponent implements OnInit {
  newsletterForm!: FormGroup;
  currentPage = 0;
  newsLength = 0;
  pages = 0;
  page;
  blog;
  news;
  title = this.translate.currentLang === 'en' ? 'RESOURCES' : 'RECURSOS';
  isLoaded = false;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private titleService: Title,
    private metaService: Meta,
    public dom: DomSanitizer,
    public translate: TranslateService,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.page) {
      this.coreService.getNewsItem(this.translate.currentLang, this.activatedRoute.snapshot.params.page).subscribe(result => {
        this.page = result[0];
        this.titleService.setTitle(this.page['meta']['title']);
        this.metaService.addTags(
          [
            { property: 'og:title', content: this.page['meta']['og_title'] },
            { name: 'description', content: this.page['meta']['description'] },
            { property: 'og:description', content: this.page['meta']['og_description'] },
            { property: 'og:image', content: 'https://cms.blockassetmanagement.com' + this.page['meta']['og_image']['url'] }
          ]
        );
      })
      this.coreService.getRecentNews(this.translate.currentLang).subscribe(news => {
        this.news = news;
      });
      this.isLoaded = true;

      setTimeout(() => {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://static.addtoany.com/menu/page.js';
        head.appendChild(script);   
      }, 1000);

    }
    else if (this.activatedRoute.snapshot.queryParams.category) {
      this.blog = true;
      this.title = this.activatedRoute.snapshot.queryParams.category.toUpperCase();
      let title, description;
      if (this.translate.currentLang === 'en') {
        title = "Resources - " + this.title;
        description = 'Block Asset Management was created in 2017 by a group of friends from the traditional financial sector that shared a common interest in Blockchain technology and the belief that digital currencies and the blockchain technology behind them would revolutionise the current financial & economic system. It proved to be just the tip of the iceberg!';
      }
      else if (this.translate.currentLang === 'es') {
        title = "Recursos - " + this.title;
        description = 'Block Asset Management fue creado en 2017 por un grupo de amigos del sector financiero tradicional que compartían un interés común en la tecnología Blockchain y la creencia de que las monedas digitales y la tecnología blockchain detrás de ellas revolucionarían el sistema financiero y económico actual. ¡Resultó ser solo la punta del iceberg!';
      }
      else if (this.translate.currentLang === 'de') {
        title = "Ressourcen - " + this.title;
        description = 'Block Asset Management wurde 2017 von einer Gruppe von Freunden aus dem traditionellen Finanzsektor gegründet, die ein gemeinsames Interesse an der Blockchain-Technologie und die Überzeugung teilten, dass digitale Währungen und die dahinter stehende Blockchain-Technologie das aktuelle Finanz- und Wirtschaftssystem revolutionieren würden. Es stellte sich heraus, dass dies nur die Spitze des Eisbergs war!';
      }
      else if (this.translate.currentLang === 'fr') {
        title = "Ressources - " + this.title;
        description = "Block Asset Management a été créé en 2017 par un groupe d'amis venant du secteur financier traditionnel et partageant le même intérêt pour la technologie Blockchain ainsi que la conviction que les monnaies digitales et la technologie blockchain allaient révolutionner le système économique et financier actuel. La suite a montré que les changements allaient être encore plus importants!";
      }

      this.titleService.setTitle(title);
      this.metaService.addTags(
        [
          { property: 'og:title', content: title},
          { name: 'description', content: description },
          { property: 'og:description', content: description }
        ]
      );
      this.isLoaded = true;
      if (this.activatedRoute.snapshot.queryParams.category === 'news') {
        this.coreService.getNewsCount().subscribe(newsCount => {
          this.newsLength = newsCount;
          this.pages = Math.ceil(newsCount / 3);
        });
        this.coreService.getNews(this.translate.currentLang, '', 0, 3).subscribe(news => {
          this.news = news;
        });
        this.isLoaded = true;
      } else if (this.activatedRoute.snapshot.queryParams.category === 'research') {
        this.coreService.getNewsResearchCount().subscribe(newsCount => {
          this.newsLength = newsCount;
          this.pages = Math.ceil(newsCount / 3);
        });
        this.coreService.getNewsResearch(this.translate.currentLang, '', 0, 3).subscribe(news => {
          this.news = news;
        });
      } else if (this.activatedRoute.snapshot.queryParams.category === 'insights') {
        this.coreService.getNewsInsightCount().subscribe(newsCount => {
          this.newsLength = newsCount;
          this.pages = Math.ceil(newsCount / 3);
        });
        this.coreService.getNewsInsight(this.translate.currentLang, '', 0, 3).subscribe(news => {
          this.news = news;
        });
        this.isLoaded = true;
      }
    } else {
      this.blog = true;
      let title, description;
      if (this.translate.currentLang === 'en') {
        title = "Resources";
        description = 'Block Asset Management was created in 2017 by a group of friends from the traditional financial sector that shared a common interest in Blockchain technology and the belief that digital currencies and the blockchain technology behind them would revolutionise the current financial & economic system. It proved to be just the tip of the iceberg!';
      }
      else if (this.translate.currentLang === 'es') {
        title = "Recursos";
        description = 'Block Asset Management fue creado en 2017 por un grupo de amigos del sector financiero tradicional que compartían un interés común en la tecnología Blockchain y la creencia de que las monedas digitales y la tecnología blockchain detrás de ellas revolucionarían el sistema financiero y económico actual. ¡Resultó ser solo la punta del iceberg!';
      }
      else if (this.translate.currentLang === 'de') {
        title = "Ressourcen";
        description = 'Block Asset Management wurde 2017 von einer Gruppe von Freunden aus dem traditionellen Finanzsektor gegründet, die ein gemeinsames Interesse an der Blockchain-Technologie und die Überzeugung teilten, dass digitale Währungen und die dahinter stehende Blockchain-Technologie das aktuelle Finanz- und Wirtschaftssystem revolutionieren würden. Es stellte sich heraus, dass dies nur die Spitze des Eisbergs war!';
      }
      else if (this.translate.currentLang === 'fr') {
        title = "Ressources";
        description = "Block Asset Management a été créé en 2017 par un groupe d'amis venant du secteur financier traditionnel et partageant le même intérêt pour la technologie Blockchain ainsi que la conviction que les monnaies digitales et la technologie blockchain allaient révolutionner le système économique et financier actuel. La suite a montré que les changements allaient être encore plus importants!";
      }

      this.titleService.setTitle(title);
      this.metaService.addTags(
        [
          { property: 'og:title', content: title},
          { name: 'description', content: description },
          { property: 'og:description', content: description }
        ]
      );

      this.coreService.getAllNewsCount().subscribe(newsCount => {
        this.newsLength = newsCount;
        this.pages = Math.ceil(newsCount / 6);
      });
      this.coreService.getAllNews(this.translate.currentLang, 0, 6).subscribe(news => {
        this.news = news;
      });
      this.isLoaded = true;
    }
    var links = document.getElementsByTagName('a');
    for (var i=0, len=links.length; i < len; i++) {
      links[i].target = '_blank';
    }
  }

  changePage(page) {
    if (page < 0) {
      this.currentPage = 0;
    } else if (page >= this.pages) {
      this.currentPage = this.pages - 1;
    } else {
      this.currentPage = page;
    }
    if (this.activatedRoute.snapshot.queryParams.category === 'news') {
      this.coreService.getNews(this.translate.currentLang, '', this.currentPage * 3, 3).subscribe(news => {
        this.news = news;
      });
    } else if (this.activatedRoute.snapshot.queryParams.category === 'research') {
      this.coreService.getNewsResearch(this.translate.currentLang, '', this.currentPage * 3, 3).subscribe(news => {
        this.news = news;
      });
    } else if (this.activatedRoute.snapshot.queryParams.category === 'insights') {
      this.coreService.getNewsInsight(this.translate.currentLang, '', this.currentPage * 3, 3).subscribe(news => {
        this.news = news;
      });
    } else {
      this.coreService.getAllNews(this.translate.currentLang, this.currentPage * 6, 6).subscribe(news => {
        this.news = news;
      });
    }
  }

  convert(content) {
    return marked(content);
  }

  createForm() {
    this.newsletterForm = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      email: ['',
        [Validators.required, Validators.email]
      ],
      privacyPolicy: [,
        Validators.required
      ],
      accessDate: [true,
        Validators.required
      ],
      marketingDate: [true,
        Validators.required
      ],
      profilingDate: [true,
        Validators.required
      ],
    });
  }

  sendNewsletter() {
    if (this.newsletterForm.invalid) {
      return;
    }

    var currentDate = new Date();

    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //Be careful! January is 0 not 1
    var year = currentDate.getFullYear();

    var dateString = ('0' + date).slice(-2) + "/" + ('0' + (month + 1)).slice(-2) + "/" + year;

    const newsletter = {
      name: this.newsletterForm.get('name')!.value,
      email: this.newsletterForm.get('email')!.value,
      privacyPolicy: this.newsletterForm.get('privacyPolicy')!.value,
      accessDate: dateString,
      marketingDate: dateString,
      profilingDate: dateString,
    };

    this.coreService.postNewsletter(newsletter).subscribe((result) => {
      this.router.navigate(['thanks'], { relativeTo: this.activatedRoute });
    }, (error) => {
    });
  }

  getImage(item) {
    if (item === 'News') {
      return 'https://cms.blockassetmanagement.com/uploads/Blog_6_2ae0061d43.jpg';
    } else if (item === 'Insights') {
      return 'https://cms.blockassetmanagement.com/uploads/Blog_4_f615f8e13b.jpg';
    } else {
      return 'https://cms.blockassetmanagement.com/uploads/Blog_3_47e7546f90.jpg';
    }
  }
}
