<div class="container-scroller">
  <app-navbar></app-navbar>
  <div class=" page-body-wrapper d-flex">
    <div class="main-panel w-100">
      <div class="content-wrapper">
        <div class="h-100">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
