<div class="container" *ngIf="profile">
    <h1 class="text-center mt-5">{{ 'PROFILE' | translate}}</h1>
    <div class="row mb-5 pb-5">
        <div class="col-lg-12 mt-5 px-5">
            <form [formGroup]="userForm" class="row p-5 border border-info">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="">{{ 'Username' | translate}}</label>
                        <input type="text" placeholder="{{ 'Username' | translate}}" name="username"
                            formControlName="username" class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('username')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <!-- <label class="mb-1" for="">{{ 'Please select which one of the following applies to you:' |
                        translate}}</label>
                    <select placeholder="Your Country" formControlName="work" class="form-control text-primary">
                        <option value="Financial Advisor">{{ 'I am a licensed/regulated Financial Advisor or Broker Dealer'
                            | translate}}
                        </option>
                        <option value="Wealth Manager">{{ 'I work for an authorized/regulated Wealth Manager/Family Office'
                            | translate}}
                        </option>
                        <option value="regulated Insurance">{{ 'I work for an authorized/regulated Insurance/Life Co' |
                            translate}}
                        </option>
                        <option value="regulated Bank">{{ 'I work for an authorized/regulated Bank/Private Bank' |
                            translate}}</option>
                        <option value="Fund Manager">{{ 'I am a Fund Manager' | translate}}</option>
                        <option value="qualified investor">{{ 'I am a qualified investor (HNWI or institutional investor)' |
                            translate}}
                        </option>
                    </select> -->
                    <label class="mb-1" for="">{{ 'Investor Type:' | translate}}</label>
                    <select placeholder="Your Country" formControlName="work" class="form-control text-primary">
                        <option value="INDIVIDUAL">{{ 'INDIVIDUAL' | translate}}
                        </option>
                        <option value="FINANCIAL ADVISOR">{{ 'FINANCIAL ADVISOR' | translate}}
                        </option>
                        <option value="INSTITUTIONAL">{{ 'INSTITUTIONAL' | translate}}
                        </option>
                        <option value="FAMILY OFFICE">{{ 'FAMILY OFFICE' | translate}}</option>
                    </select>
                    <small class="text-danger" *ngIf="showError('work')">
                        {{ 'This field is required' | translate}}
                    </small>
                    <div class="form-group">
                        <label for="">{{ 'First Name' | translate}}</label>
                        <input type="text" placeholder="{{ 'Name' | translate}}" name="name" formControlName="name"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('name')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Last Name' | translate}}</label>
                        <input type="text" placeholder="{{ 'Last Name' | translate}}" formControlName="surname"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('surname')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="">{{ 'Password' | translate}}</label>
                        <input type="text" placeholder="{{ 'Password' | translate}}" name="password"
                            formControlName="password" class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('password')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Your Email' | translate}}</label>
                        <input type="text" placeholder="Email" formControlName="email"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('email')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Phone Number' | translate}}</label>
                        <input type="text" placeholder="{{ 'Phone Number' | translate}}" formControlName="phone"
                            class="form-control text-primary">
                        <small class="text-danger" *ngIf="showError('phone')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="">{{ 'Country:' | translate}}</label>
                        <select placeholder="Your Country" formControlName="country" class="form-control text-primary">
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The
                            </option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-bissau">Guinea-bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic
                                of
                            </option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav
                                Republic
                                of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South
                                Sandwich
                                Islands</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-leste">Timor-leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands
                            </option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                        <small class="text-danger" *ngIf="showError('country')">
                            {{ 'This field is required' | translate}}
                        </small>
                    </div>

                </div>
                <div class="text-center">
                    <button class="bg-primary border-primary mt-5 p-0 px-3" (click)="save()">{{ 'SUBMIT' |
                        translate}}</button>
                </div>
                <small class="text-success" *ngIf="alert.type == 1">
                    {{alert.message}}
                </small>
                <small class="text-danger" *ngIf="alert.type == 2">
                    {{alert.message}}
                </small>
            </form>
        </div>
    </div>
</div>

<div class="container" *ngIf="!profile">
    <h1 class="text-center mt-5">{{ 'INVESTMENT NOTIFICATION FORM' | translate}}</h1>
    <div class="row mb-5 pb-5">
        <div class="alert alert-primary text-center" role="alert" *ngIf="alert.type == 1">
            {{alert.message}}
        </div>
        <!-- <small class="text-success text-center" *ngIf="alert.type == 1">
            {{alert.message}}
        </small> -->
        <small class="text-danger text-center" *ngIf="alert.type == 2">
            {{alert.message}}
        </small>
        <div class="col-lg-12 mt-5 px-5">
            <form [formGroup]="notificationForm" class="row p-5 border border-info">
                <div class="form-group col-lg-6">
                    <label for="">{{ 'Your name' | translate}}</label>
                    <input type="text" placeholder="{{ 'Your name' | translate}}" name="name" formControlName="name"
                        class="form-control text-primary">
                    <small class="text-danger" *ngIf="showNotificationError('name')">
                        {{ 'This field is required' | translate}}
                    </small>
                </div>
                <div class="form-group col-lg-6">
                    <label for="">{{ 'Your email' | translate}}</label>
                    <input type="text" placeholder="{{ 'Your email' | translate}}" name="email" formControlName="email"
                        class="form-control text-primary">
                    <small class="text-danger" *ngIf="showNotificationError('email')">
                        {{ 'This field is required' | translate}}
                    </small>
                </div>
                <div class="form-group col-lg-6 mt-3">
                    <label for="">{{ 'Broker/Distributor Name' | translate}}</label>
                    <input type="text" placeholder="{{ 'Broker/Distributor Name' | translate}}"
                        formControlName="brokerage" class="form-control text-primary">
                    <small class="text-danger" *ngIf="showNotificationError('brokerage')">
                        {{ 'This field is required' | translate}}
                    </small>
                </div>
                <div class="form-group col-lg-6 mt-3">
                    <label for="">{{ 'Used Platform/Channel (Bank/Fund Platform/Life Company)' | translate}}</label>
                    <input type="text" placeholder="{{ 'Used Platform/Channel (Bank/Fund Platform/Life Company)' | translate}}"
                        formControlName="provider" class="form-control text-primary">
                    <small class="text-danger" *ngIf="showNotificationError('provider')">
                        {{ 'This field is required' | translate}}
                    </small>
                </div>
                <div class="form-group col-lg-6 mt-3">
                    <label for="">{{ 'Client Reference/Policy Number/Deal Reference' | translate}}</label>
                    <input type="text" placeholder="{{ 'Client Reference/Policy Number/Deal Reference' | translate}}"
                        formControlName="client" class="form-control text-primary">
                    <small class="text-danger" *ngIf="showNotificationError('client')">
                        {{ 'This field is required' | translate}}
                    </small>
                </div>
                <div class="form-group col-lg-6 mt-3">
                    <label for="">{{ 'Product Name' | translate}}</label>
                    <select (change)="productType(notificationForm.get('product')!.value)" placeholder="Product Name" formControlName="product" class="form-control text-primary">
                        <option value="BLOCKCHAIN STRATEGIES FUND">{{ 'BLOCKCHAIN STRATEGIES FUND' |
                            translate}}
                        </option>
                        <option value="BLOCKCHAIN MULTI-STRATEGY TRACKER CERTIFICATE">{{ 'BLOCKCHAIN MULTI-STRATEGY TRACKER CERTIFICATE'
                            | translate}}
                        </option>
                    </select>
                </div>
                <ng-container *ngIf="currentProductType == 'BLOCKCHAIN STRATEGIES FUND'">
                    <div class="form-group col-lg-6 mt-3">
                        <label for="">{{ 'Share Class:' | translate}}</label>
                        <select placeholder="Share Class" formControlName="class" class="form-control text-primary">
                            <option value="Class A (USD) - LU1738364063 / min. USD 10k + USD 1k">{{ 'Class A (USD) - LU1738364063 / min. USD 10k + USD 1k' |
                                translate}}
                            </option>
                            <option value="Class B (EUR) - LU1738363925 / min. EUR 10k + EUR 1k">{{ 'Class B (EUR) - LU1738363925 / min. EUR 10k + EUR 1k'
                                | translate}}
                            </option>
                            <option value="Class C (USD) - LU2521015862 / min. USD 100k + USD 100k">{{ 'Class C (USD) - LU2521015862 / min. USD 100k + USD 100k'
                                | translate}}
                            </option>
                            <option value="Class I (USD) - LU2521015946 / min. USD 500k + USD 100k">{{ 'Class I (USD) - LU2521015946 / min. USD 500k + USD 100k'
                                | translate}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-lg-6 mt-3">
                        <label for="">{{ 'Investment Amount' | translate}}</label>
                        <!-- <input type="text" placeholder="{{ 'Investment Amount' | translate}}" (blur)="transform()"
                            formControlName="amount" class="form-control text-primary"> -->
                        <input type="text" placeholder="{{ 'Investment Amount' | translate}}"
                            [value]="amountDots(notificationForm.get('amount')!.value, 'amount')" formControlName="amount"
                            class="form-control text-primary">
                    </div>
                    <div class="form-group col-lg-6 mt-3">
                        <label for="">{{ 'Initial Commission to be applied:' | translate}}</label>
                        <select placeholder="Initial commission to be applied" formControlName="hasInititalCommission" class="form-control text-primary">
                            <option value="true">{{ 'YES' |
                                translate}}
                            </option>
                            <option value="false">{{ 'NO'
                                | translate}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-lg-6 mt-3">
                        <label for="">{{ 'Initial Commission In % (max 4%)' | translate}}</label>
                        <select placeholder="Initial Commission In % (max 4%)" formControlName="commission" class="form-control text-primary">
                            <option value="1">{{ '1' |
                                translate}}
                            </option>
                            <option value="2">{{ '2'
                                | translate}}
                            </option>
                            <option value="3">{{ '3' |
                                translate}}
                            </option>
                            <option value="4">{{ '4'
                                | translate}}
                            </option>
                        </select>
                        <!-- <input type="number" min="0" max="4" (blur)="transformComm()" placeholder="{{ 'Initial comm % (max 4%)' | translate}}"
                            formControlName="commission" class="form-control text-primary"> -->
                    </div>
                </ng-container>
                
                <ng-container *ngIf="currentProductType == 'BLOCKCHAIN MULTI-STRATEGY TRACKER CERTIFICATE'">
                    <div class="form-group col-lg-6 mt-3">
                        <label for="">{{ 'ISIN: ' | translate}}</label>
                        <input type="text" placeholder="{{ 'CH0451798356' | translate}}"
                            [value]="notificationForm.get('isin')!.value" formControlName="isin"
                            class="form-control text-primary" readonly>
                        <!-- <div class="form-check form-check-inline mx-5">
                            <input formControlName="isin" class="form-check-input" type="radio" name="isin" id="isin1"
                                value="CH0451798356" [checked]="true">
                            <label class="form-check-label" for="isin1">CH0451798356</label>
                        </div> -->
                        <!-- <div class="form-check form-check-inline mx-5">
                            <input formControlName="isin" class="form-check-input" type="radio" name="isin" id="isin1"
                                value="LU1738364063">
                            <label class="form-check-label" for="isin1">LU1738364063</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input formControlName="isin" class="form-check-input" type="radio" name="isin" id="isin2"
                                value="LU1738363925">
                            <label class="form-check-label" for="isin2">LU1738363925</label>
                        </div> -->
                    </div>
                    <div class="form-group col-lg-6 mt-3">
                        <label for="">{{ 'Number Of Certificates (min. 2)' | translate}}</label>
                        <!-- <input type="text" placeholder="{{ 'Investment Amount' | translate}}" (blur)="transform()"
                            formControlName="amount" class="form-control text-primary"> -->
                        <input type="text" placeholder="{{ 'Number Of Certificates (min. 2)' | translate}}"
                            [value]="amountDots(notificationForm.get('certificatesAmount')!.value, 'certificatesAmount')" formControlName="certificatesAmount"
                            class="form-control text-primary" (change)="checkMin(notificationForm.get('certificatesAmount')!.value)">
                    </div>
                </ng-container>
                <div class="form-group col-lg-12 mt-3">
                    <label for="">{{ 'Any additional comment:' | translate}}</label>
                    <!-- <input type="text" placeholder="{{ 'Investment Amount' | translate}}" (blur)="transform()"
                        formControlName="amount" class="form-control text-primary"> -->
                    <textarea placeholder="{{ 'Any additional comment:' | translate}}"
                        [value]="notificationForm.get('comments')!.value" formControlName="comments"
                        class="form-control text-primary"></textarea>
                </div>
                <div class="form-group col-lg-6 mt-3">
                    <label for="">{{ 'Date of subscription (Trade Date):' | translate}}</label>
                    <!-- <input type="text" placeholder="{{ 'Investment Amount' | translate}}" (blur)="transform()"
                        formControlName="amount" class="form-control text-primary"> -->
                    <input type="text" placeholder="{{ 'Date of subscription (Trade Date)' | translate}}"
                        [value]="notificationForm.get('currentDate')!.value" formControlName="currentDate"
                        class="form-control text-primary">
                    <small class="text-danger" *ngIf="showNotificationError('currentDate')">
                        {{ 'This field is required' | translate}}
                    </small>
                </div>
                <!-- <div class="form-group col-lg-6 mt-4">
                    <label for="">{{ 'Investment Currency: ' | translate}}</label>
                    <div class="form-check form-check-inline mx-5">
                        <input formControlName="currency" class="form-check-input" type="radio" name="currency"
                            id="currency1" value="LU1738364063">
                        <label class="form-check-label" for="currency1">USD</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input formControlName="currency" class="form-check-input" type="radio" name="currency"
                            id="currency2" value="LU1738363925">
                        <label class="form-check-label" for="currency2">EUR</label>
                    </div>
                </div> -->
                <div class="text-center">
                    <button class="bg-primary border-primary mt-5 p-0 px-3" (click)="saveNotification()">{{ 'SUBMIT' |
                        translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>