import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm!: FormGroup;
  alert = { "type": null, "message": null };
  constructor(
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Contact Us');
    this.metaService.addTags(
      [
        { property: 'og:title', content: 'Contact Us' },
        { name: 'description', content: 'SEND US AN EMAIL, WE ENDEAVOUR TO ANSWER ALL ENQUIRIES WITHIN 24H' },
        { property: 'og:description', content: 'SEND US AN EMAIL, WE ENDEAVOUR TO ANSWER ALL ENQUIRIES WITHIN 24H' }
      ]
    );
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      email: ['',
        [Validators.required, Validators.email]
      ],
      question: ['',
        Validators.required
      ],
      privacyPolicy: [,
        Validators.required
      ],
      accessDate: [true,
        Validators.required
      ],
      marketingDate: [true,
        Validators.required
      ],
      profilingDate: [true,
        Validators.required
      ],
    });
  }

  sendContact() {
    if (this.contactForm.invalid || !this.contactForm.get('privacyPolicy').value) {
      this.alert.type = 'error';
      this.alert.message = 'The message could not be sent';
      return;
    }
    const sendContact = {
      to: "info@blockassetmanagement.com",
      subject: "Nuevo Contacto",
      html: `<h1>Nuevo contacto:</h1>
      <h3>Email: ${this.contactForm.get('email')!.value}</h3>
      <h3>Name: ${this.contactForm.get('name')!.value}</h3><h3>Question: ${this.contactForm.get('question')!.value}`,
    };

    var currentDate = new Date();

    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //Be careful! January is 0 not 1
    var year = currentDate.getFullYear();

    var dateString = ('0' + date).slice(-2) + "/" + ('0' + (month + 1)).slice(-2) + "/" + year;

    const contact = {
      name: this.contactForm.get('name')!.value,
      email: this.contactForm.get('email')!.value,
      privacyPolicy: this.contactForm.get('privacyPolicy')!.value,
      accessDate: dateString,
      marketingDate: dateString,
      profilingDate: dateString,
    };

    this.coreService.sendContact(sendContact).subscribe((result) => {
      this.coreService.postContact(contact).subscribe((result) => {
        this.createForm();
      }, (error) => {
      });
    }, (error) => {
      this.alert.message = error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 10000);
    });
  }
}
