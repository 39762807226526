<div>
    <div class="container pb-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 mt-5 px-5">
                <div *ngIf="!confirmation" class="p-5 border border-info text-center">
                    <h2>{{ 'Welcome to BAM World!' | translate}}</h2>
                    <h5>{{page === 'blockchain-strategies-fund' || page === 'blockchain-strategies-fund-es' || page === 'blockchain-strategies-fund-de' || page === 'blockchain-strategies-fund-fr' ? translate.instant('Thank you for registering at Blockassetmanagement') : translate.instant('Thanks for signing up to our newsletter!')}}</h5>
                    <h6>{{page === 'blockchain-strategies-fund' || page === 'blockchain-strategies-fund-es' || page === 'blockchain-strategies-fund-de' || page === 'blockchain-strategies-fund-fr' ? translate.instant('You will shortly receive an email with the latest Factsheet.') : translate.instant('You will be notified once or twice a week maximum when a new article is published.')}}</h6>
                        <img class="w-100" src="https://cms.blockassetmanagement.com/uploads/4th_anniversary_4d7d3b9142.jpeg" alt="anniversary">
                </div>
                <div *ngIf="confirmation" class="p-5 border border-info text-center">
                    <h2>{{ 'Welcome to BAM World!' | translate}}</h2>
                    <h5>{{translate.instant('Thank you for confirm your email at Blockassetmanagement')}}</h5>
                    <button class="bg-primary border-primary m-5 p-0 px-3"><a class="text-white" [href]="translate.currentLang + '/login'">LOGIN</a></button>
                        <img class="w-100" src="https://cms.blockassetmanagement.com/uploads/4th_anniversary_4d7d3b9142.jpeg" alt="anniversary">
                </div>
            </div>
        </div>
    </div>
</div>
